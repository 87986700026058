import React, {memo} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';

import './FiltersList.scss';

import {AppButton} from '../../UI';
import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as Star} from '../../../Assets/Icons/Globals/star.svg';

const FiltersList = ({GROUP_DATA, activeGroupId, setActiveGroupId}) => {
  const {width} = useScreenSize();

  return (
    <div className="filtersListContainer blueBackground z-index-1">
      <Swiper
        freeMode
        slidesPerGroup={1}
        slidesPerView="auto"
        keyboard={width > BREAKPOINT_XS}
        mousewheel={width > BREAKPOINT_XS}
        navigation={width > BREAKPOINT_XS}
        spaceBetween={width > BREAKPOINT_XS ? 8 : 5}
        slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
        slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
        className="filtersListSwiper flex items-center mt-sm"
        modules={[
          FreeMode,
          ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
        ]}>
        {GROUP_DATA?.map(item => (
          <SwiperSlide key={item?.title}>
            <AppButton
              type={19}
              height={30}
              className="flex items-center gap-5 py-xs"
              active={item?.groupId === activeGroupId}
              onClick={() => setActiveGroupId(item?.groupId)}
              title={
                item?.icon ? (
                  <div className="px-md items-center justify-center row">
                    <Star width={25} height={25} fill="var(--white)" />
                  </div>
                ) : (
                  <div className="flex row justify-center textCenter filterItemContainer gap-5 py-sm px-lg">
                    <span className="text-caption bold-600">
                      {item?.title?.toUpperCase()}
                    </span>
                    {/*<span className="text-caption bold-600">*/}
                    {/*  ({item?.data?.length})*/}
                    {/*</span>*/}
                  </div>
                )
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(FiltersList);
