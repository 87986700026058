import React, {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import Countdown, {zeroPad} from 'react-countdown';

import {checkIsToday, formatScoreTimestamp} from '../../Utils/TimeConvertors';

const StartTime = ({game}) => {
  const {t} = useTranslation();

  const isToday = useMemo(() => checkIsToday(game?.start_ts), [game?.start_ts]);

  const minutesBeforeGame = useMemo(
    () => Math.round((new Date(game?.start_ts * 1000) - new Date()) / 1000),
    [game?.start_ts],
  );

  return (
    !game?.is_live && (
      <div>
        {isToday &&
        !game?.is_live &&
        !!minutesBeforeGame &&
        +minutesBeforeGame > 0 &&
        +minutesBeforeGame < 3600 ? (
          <div className="flex items-center gap-5 grayText wrap justify-center">
            <span className="font-10 bold-600 whiteText">
              {t('startingIn')}
            </span>
            <Countdown
              date={Date.now() + +minutesBeforeGame * 1000}
              renderer={({hours, minutes, seconds, completed}) => (
                <span className="font-10 bold-600 whiteText">
                  {zeroPad(minutes)}{' '}
                </span>
              )}
            />
            <span className="font-10 bold-600 whiteText">{t('M')}</span>
          </div>
        ) : (
          <span className="font-10 bold-600 whiteText">
            {formatScoreTimestamp(game?.start_ts)}
          </span>
        )}
      </div>
    )
  );
};

export default memo(StartTime);
