import React from 'react';

const CyberFootballSetsScore = ({game}) => {
  return (
    <>
      {!!game?.info?.score1 &&
        typeof game?.info?.score1 !== 'undefined' &&
        !!game?.is_live && (
          <span
            className="orangeText text-caption bold-700 "
            style={{lineHeight: 1.25}}>
            {game?.info?.score1}{' '}
          </span>
        )}
      {!!game?.info?.score2 &&
        typeof game?.info?.score2 !== 'undefined' &&
        !!game?.is_live && (
          <span
            className="orangeText text-caption bold-700 "
            style={{lineHeight: 1.25}}>
            {game?.info?.score2}{' '}
          </span>
        )}
    </>
  );
};

export default CyberFootballSetsScore;
