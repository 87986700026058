import React, {useMemo} from 'react';

const VolleyballSetsScore = ({game}) => {
  const setValues = game?.stats?.[`score_${game?.info?.current_game_state}`];

  const isFirstTeamWins = useMemo(() => {
    return setValues?.team1_value > setValues?.team2_value;
  }, [setValues?.team1_value, setValues?.team2_value]);

  const isSecondTeamWins = useMemo(() => {
    return setValues?.team2_value > setValues?.team1_value;
  }, [setValues?.team1_value, setValues?.team2_value]);

  return (
    <div className="flex rowCenter gap-12">
      <div className="flex column" style={{height: 30}}>
        {game?.info?.pass_team === 'team1' ? (
          <div style={{height: 15}} className="flex rowCenter">
            <img
              width={9}
              height={9}
              // style={{marginBottom: 31}}
              src={require(
                `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
              )}
            />
          </div>
        ) : (
          <div style={{height: 15}}></div>
        )}
        {game?.info?.pass_team === 'team2' ? (
          <div style={{height: 15}} className="flex rowCenter">
            <img
              width={9}
              height={9}
              // style={{marginBottom: 31}}
              src={require(
                `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
              )}
            />
          </div>
        ) : (
          <div style={{height: 15}}></div>
        )}
      </div>
      <div className="flex column items-center">
        <span
          className="text-caption bold-700 "
          style={{lineHeight: 1.25, opacity: isFirstTeamWins ? 1 : 0.5}}>
          {setValues?.team1_value || 0}
        </span>
        <span
          className="text-caption bold-700 "
          style={{lineHeight: 1.25, opacity: isSecondTeamWins ? 1 : 0.5}}>
          {setValues?.team2_value || 0}
        </span>
      </div>
      <div
        style={{
          width: 1,
          height: 27,
          opacity: 0.15,
        }}
        className="blueBackground"></div>
      <div className="flex column items-center">
        {!!game?.info?.score1 &&
          typeof game?.info?.score1 !== 'undefined' &&
          !!game?.is_live && (
            <span
              className="orangeText text-caption bold-700 "
              style={{lineHeight: 1.25}}>
              {game?.info?.score1}{' '}
            </span>
          )}
        {!!game?.info?.score2 &&
          typeof game?.info?.score2 !== 'undefined' &&
          !!game?.is_live && (
            <span
              className="orangeText text-caption bold-700 "
              style={{lineHeight: 1.25}}>
              {game?.info?.score2}{' '}
            </span>
          )}
      </div>
    </div>
  );
};

export default VolleyballSetsScore;
