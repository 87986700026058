import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {
  getRecaptcha,
  getMainMenuModal,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setShowMobileBetSlip} from '../../../Redux/AppSlice';

import {useOutSideClickHandler, useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {PRIVACY_POLICY_HEADER_ITEMS} from '../../../Constants/Menu';

import {
  AppIntro,
  AppBetSlip,
  AppRecaptcha,
  AppSuccessModal,
  AppButton,
} from '../../../Components/UI';
import MyBets from '../../../Components/MyBets';
import Header from '../../../Components/Header';
import AuthStack from '../../../Components/Auth';
import MainMenu from '../../../Components/MainMenu';
import NewFooter from '../../../Components/NewFooter';
import NewMainMenu from '../../../Components/NewMainMenu';
import MobileHeader from '../../../Components/Header/MobileHeader';
import MobileBottomTab from '../../../Components/Home/MobileBottomTab';
import BetSlipSuccessModal from '../../../Components/BetSlip/Components/SuccessModal';

const Privacy = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const mobileBetslipRef = useRef(null);

  const [startY, setStartY] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = useCallback(e => {
    if (e.target.id === 'headerDragArea') {
      setStartY(e.touches[0].clientY);
      setIsDragging(true);
    }
  }, []);

  const handleTouchMove = useCallback(
    e => {
      if (!isDragging) return;
      const distance = e.touches[0].clientY - startY;
      if (distance > 0) setTranslateY(distance);
    },
    [isDragging, startY],
  );

  const handleTouchEnd = useCallback(() => {
    if (translateY > 0) {
      dispatch(setShowMobileBetSlip(false));
      setTranslateY(0);
      setIsDragging(false);
    }
  }, [dispatch, translateY]);

  useOutSideClickHandler(mobileBetslipRef, () =>
    dispatch(setShowMobileBetSlip(false)),
  );

  const shareBetId = useMemo(() => getParam.get('shareBetId'), [getParam]);

  const recaptcha = useSelector(getRecaptcha);
  const mainMenuModal = useSelector(getMainMenuModal);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  const onClick = useCallback(
    (title, html) => {
      getParam.set('tab', title);
      setGetParam(getParam);
    },
    [getParam, setGetParam],
  );

  useEffect(() => {
    if (showMobileBetSlip) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [showMobileBetSlip]);

  return (
    <div className="privacyPageContainer darkBlueBackground fit">
      {width > BREAKPOINT_XS ? (
        <Header showTabs={false} showSearch={false} />
      ) : (
        <MobileHeader showFilters={false} showSearch={false} />
      )}
      <div
        style={{boxShadow: '0px 3px 3px 0px #00000040'}}
        className="blueBackground row items-center justify-center py-xxl gap-10">
        <Swiper
          freeMode
          keyboard
          mousewheel
          spaceBetween={5}
          slidesPerView="auto"
          modules={[Keyboard, FreeMode]}
          className="privacyPolicyPageSwiper">
          {PRIVACY_POLICY_HEADER_ITEMS?.map(({title, html}) => (
            <SwiperSlide key={title}>
              <AppButton
                type={6}
                height={40}
                fontSize={15}
                title={t(title)}
                width="fit-content"
                className="py-md px-xl"
                titleClassName="bold-600"
                onClick={() => onClick(title, html)}
                active={getParam.get('tab') === title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className="mt-xs blueBackground"
        style={{padding: width < BREAKPOINT_XS ? '10px' : '70px 50px'}}>
        <div
          style={{border: '1px solid white'}}
          dangerouslySetInnerHTML={{
            __html: PRIVACY_POLICY_HEADER_ITEMS?.find(
              item => item?.title === getParam.get('tab'),
            )?.html,
          }}
          className="flex column whiteText fit rounded-borders px-xxl py-md font-10 bold-500"
        />
      </div>
      <NewFooter />
      {width < BREAKPOINT_XS && <MobileBottomTab />}
      <AuthStack />
      {width < BREAKPOINT_XS && (
        <div
          className={`appBetSlipWrapper_mobile flex justify-center fit rounded-top--borders column appBetSlipWrapper ${
            showMobileBetSlip ? 'slide-up' : `slide-down`
          }`}
          ref={mobileBetslipRef}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          style={{
            zIndex: 16,
            background: 'rgba(0, 0, 0, 0.7)',
          }}>
          <div
            className="row justify-center fit"
            style={{
              marginTop: 16,
              borderRadius: 12,
            }}>
            <div
              style={{
                top: 18,
                height: 1,
                width: 50,
                zIndex: 16,
                position: 'absolute',
                background: '#80858B',
              }}
            />
            <AppBetSlip />
          </div>
        </div>
      )}
      <MyBets />
      <AppSuccessModal />
      {recaptcha?.isVisible && <AppRecaptcha />}
      <AppIntro />
      {!!shareBetId && (
        <BetSlipSuccessModal
          isSharedBet
          betId={shareBetId}
          isOpen={!!shareBetId}
          setIsOpen={() => {
            getParam.delete('shareBetId');
            setGetParam(getParam);
          }}
        />
      )}
      {width < BREAKPOINT_XS && !mainMenuModal?.isDesktop ? (
        <NewMainMenu />
      ) : (
        <MainMenu />
      )}
    </div>
  );
};

export default Privacy;
