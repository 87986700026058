export const CATEGORY_LIVE_CASINO_ID = 28;
export const CATEGORY_SKILL_GAMES_ID = 36;
export const CATEGORY_BOOSTED_RTP_GAMES_ID = 860;

export const CATEGORY_SLOTS_GAMES_ID = 51;

export const CATEGORY_POKER_ID = 171717;
export const CATEGORY_BIG_HILO_ID = 420014065;
export const CATEGORY_BLAST_ID = 33060327;
export const CATEGORY_CRASH_ID = 33060328;

export const CATEGORY_AVIATOR_ID = 806666;
export const CATEGORY_DICE_ID = 426634207;

export const ICONS_SHOW_TYPE = 'icons';
export const NAMES_SHOW_TYPE = 'names';
export const PROVIDERS_ALL_GAMES_NAME = 'all';
export const PROVIDERS_ALL_GAMES_TITLE = 'All Games';
export const SUB_CATEGORIES_LAST_PLAYED = 'lastPlayed';

export const SLOTS_NAME = 'slots';
export const SKILL_NAME = 'skill';
export const LIVE_CASINO_NAME = 'liveCasino';

export const CATEGORIES_IMAGES = {
  VideoSlots: require('../Assets/Icons/Casino/VideoSlots.png'),
  JackpotMania: require('../Assets/Icons/Casino/JackpotMania.png'),
  CrashGames: require('../Assets/Icons/Casino/CrashGames.png'),
  BuyFeature: require('../Assets/Icons/Casino/BuyFeature.png'),
  TOP20: require('../Assets/Icons/Casino/TOP20.png'),
  InstantGame: require('../Assets/Icons/Casino/InstantGame.png'),
  PopularGames: require('../Assets/Icons/Casino/PopularGames.png'),
  Roulette: require('../Assets/Icons/Casino/Roulette.png'),
  LotteryGames: require('../Assets/Icons/Casino/LotteryGames.png'),
  'Fishing&Hunting': require('../Assets/Icons/Casino/Fishing&Hunting.png'),
  Jackpots: require('../Assets/Icons/Casino/Jackpots.png'),
  Slots: require('../Assets/Icons/Casino/Slots.png'),
  VirtualBetting: require('../Assets/Icons/Casino/VirtualBetting.png'),
  InstantWin: require('../Assets/Icons/Casino/InstantWin.png'),
  New: require('../Assets/Icons/Casino/New.png'),
  TopSlots: require('../Assets/Icons/Casino/TopSlots.png'),
  BuyBonus: require('../Assets/Icons/Casino/BuyBonus.svg'),
  ClassicSlots: require('../Assets/Icons/Casino/ClassicSlots.png'),
  TableGames: require('../Assets/Icons/Casino/TableGames.png'),
  OtherGames: require('../Assets/Icons/Casino/OtherGames.png'),
  ScratchGames: require('../Assets/Icons/Casino/ScratchGames.png'),
  VideoPoker: require('../Assets/Icons/Casino/VideoPoker.png'),
  BettingGames: require('../Assets/Icons/Casino/BettingGames.png'),
  Megaways: require('../Assets/Icons/Casino/Megaways.png'),
  VideoBingo: require('../Assets/Icons/Casino/VideoBingo.png'),
  SkillGames: require('../Assets/Icons/Casino/SkillGames.png'),
  Arcadegames: require('../Assets/Icons/Casino/Arcadegames.png'),
  'Non-StopDrop': require('../Assets/Icons/Casino/Non-StopDrop.png'),
  'Drops&Winsslots': require('../Assets/Icons/Casino/Drops&Winsslots.png'),
  LiveCasino: require('../Assets/Icons/Casino/LiveCasino.png'),
  Poker: require('../Assets/Icons/Casino/Poker.png'),
  Blast: require('../Assets/Icons/Casino/Blast.png'),
};
