import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard, Navigation} from 'swiper/modules';
import {
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import './index.scss';

import {useOutSideClickHandler, useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {AppButton, AppInput} from '../../UI';
import HomeSportSkeletons from '../../UI/Skeletons/HomeSportSkeletons';
import PopularCompetitionSkeletons from '../../UI/Skeletons/PopularCompetitionSkeletons';

import {getIsContentScrolled} from '../../../Redux/AppSlice/AppSelectors';
import {getIsSportDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {filterCasinoSubCategories} from '../../../Utils/FilterCasinoSubCategories';

import {
  SLOTS_FIXED_SUB_CATEGORIES,
  LIVE_CASINO_FIXED_SUB_CATEGORIES,
} from '../../../Constants/CasinoSubCategoriesArray';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {
  ACTIVE_PROVIDER_ICONS,
  PROVIDER_ICONS,
} from '../../../Constants/ProviderIcons';
import {
  ICONS_SHOW_TYPE,
  NAMES_SHOW_TYPE,
  SKILL_NAME,
  LIVE_CASINO_NAME,
  CATEGORY_LIVE_CASINO_ID,
  PROVIDERS_ALL_GAMES_NAME,
  SUB_CATEGORIES_LAST_PLAYED,
} from '../../../Constants/CasinoMenuFilter';

import allProviders from '../../../Assets/Images/Casino/all.png';
import {
  setShowCasinoSearchModal,
  setShowCasinoSideBar,
} from '../../../Redux/CasinoSlice';

import {ReactComponent as RightArrowIcon} from '../../../Assets/Icons/Casino/rightArrow.svg';
import lastPlayedImage from '../../../Assets/Images/Casino/lastPlayed.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';
import mobileLastPlayedImage from '../../../Assets/Images/Casino/mobileLastPlayed.svg';
import {ReactComponent as DownProviders} from '../../../Assets/Icons/Casino/downProviders.svg';
import {show} from 'react-modal/lib/helpers/ariaAppHider';
import SideBarProviders from '../../Casino/Providers/SideBarProviders';

import '../../Casino/Providers/SideBarProviders';
import {getShowCasinoSideBar} from '../../../Redux/CasinoSlice/CasinoSelectors';
import LobbyGameItem from '../../Casino/Lobby/LobbyGameItem';

const HomeProviders = () => {
  const params = useParams();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const {getSubCategories, getProviders, getLiveCasinoSubCategories} =
    useCasino();

  const subCategoryIdParam = useMemo(
    () => getParam.get('subCategoryId'),
    [getParam],
  );
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);

  const showCasinoSideBar = useSelector(getShowCasinoSideBar);
  const isContentScrolled = useSelector(getIsContentScrolled);
  const isSportDataLoading = useSelector(getIsSportDataLoading);

  const providersContentRef = useRef(null);

  // useOutSideClickHandler(providersContentRef, () => {
  //   setShowProviders(false);
  // });

  // const [providers, setProviders] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showProviders, setShowProviders] = useState(false);
  // const [providersLoading, setProvidersLoading] = useState(false);
  const [position, setPosition] = useState(-width); // Initial position is 0px
  const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);
  const [showProvidersSideBar, setShowProvidersSideBar] = useState(false);

  const renderProviders = useMemo(
    () => (
      <>
        {/*<SwiperSlide key="lastPlayed">*/}
        {/*  <AppButton*/}
        {/*     type={6}*/}
        {/*     width={110}*/}
        {/*     active={subCategoryIdParam === 'lastPlayed'}*/}
        {/*     height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}*/}
        {/*     title={*/}
        {/*       <div*/}
        {/*         onClick={() =>*/}
        {/*           navigate({*/}
        {/*             pathname: `/casino/${params?.category}/lastPlayed`,*/}
        {/*           })*/}
        {/*         }*/}
        {/*         className="columnCenter gap-5 lastPlayedContainer">*/}
        {/*         {!isContentScrolled && (*/}
        {/*           <img*/}
        {/*             height={18}*/}
        {/*             width={18}*/}
        {/*             src={lastPlayedImage}*/}
        {/*             alt="lastPlayedIcon"*/}
        {/*           />*/}
        {/*         )}*/}
        {/*         <span className="bold-600 font-12">{t('lastPlayed')}</span>*/}
        {/*       </div>*/}
        {/*     }*/}
        {/*   />*/}
        {/* </SwiperSlide>*/}

        {/*<SwiperSlide key="lastPlayed">*/}
        {/*  <AppButton*/}
        {/*    type={6}*/}
        {/*    height={36}*/}
        {/*    width="fit-content"*/}
        {/*    active={subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED}*/}
        {/*    title={*/}
        {/*      <div*/}
        {/*        onClick={() =>*/}
        {/*          navigate({*/}
        {/*            pathname: `/casino/lastPlayed/${params?.category}`,*/}
        {/*          })*/}
        {/*        }*/}
        {/*        className="row items-center justify-center gap-5 lastPlayedContainer">*/}
        {/*        {!isContentScrolled && (*/}
        {/*          <img*/}
        {/*            width={20}*/}
        {/*            src={lastPlayedImage}*/}
        {/*            alt="lastPlayedIcon"*/}
        {/*          />*/}
        {/*        )}*/}
        {/*        <span className="bold-600 font-12">{t('lastPlayed')}</span>*/}
        {/*      </div>*/}
        {/*    }*/}
        {/*  />*/}
        {/*</SwiperSlide>*/}
        {/*<SwiperSlide key="allProviders">*/}
        {/*  <AppButton*/}
        {/*    type={6}*/}
        {/*    height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}*/}
        {/*    title={*/}
        {/*      width < BREAKPOINT_XS ? (*/}
        {/*        <div className="columnCenter gap-5">*/}
        {/*          {!isContentScrolled && !(width < BREAKPOINT_XS) && (*/}
        {/*            <img width={25} src={allProviders} alt="allProvidersIcon" />*/}
        {/*          )}*/}
        {/*          <span className="bold-600 font-12">{t('all')}</span>*/}
        {/*        </div>*/}
        {/*      ) : (*/}
        {/*        t('allProviders')*/}
        {/*      )*/}
        {/*    }*/}
        {/*    className="flex items-center gap-5"*/}
        {/*    active={providerParam === PROVIDERS_ALL_GAMES_NAME}*/}
        {/*    onClick={() => {*/}
        {/*      getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);*/}
        {/*      setGetParam(getParam);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</SwiperSlide>*/}
        {/*{providers?.map(providerItem => (*/}
        {/*  <SwiperSlide key={providerItem?.name}>*/}
        {/*    <AppButton*/}
        {/*      type={6}*/}
        {/*      height={width < BREAKPOINT_XS && !isContentScrolled ? 23 : 'auto'}*/}
        {/*      className="flex items-center gap-5"*/}
        {/*      active={providerItem?.name === providerParam}*/}
        {/*      onClick={() => {*/}
        {/*        getParam.set('provider', providerItem?.name);*/}
        {/*        setGetParam(getParam);*/}
        {/*      }}*/}
        {/*      title={*/}
        {/*        isContentScrolled ? (*/}
        {/*          <span className="bold-600 font-12">*/}
        {/*            {providerItem?.title}*/}
        {/*          </span>*/}
        {/*        ) : (*/}
        {/*          <img*/}
        {/*            alt={providerItem?.name}*/}
        {/*            src={PROVIDER_ICONS?.[providerItem?.name]}*/}
        {/*            style={{*/}
        {/*              width: 80,*/}
        {/*              objectFit: 'contain',*/}
        {/*              height: width < BREAKPOINT_XS ? 60 : 20,*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        )*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </SwiperSlide>*/}
        {/*))}*/}
      </>
    ),
    [
      t,
      width,
      navigate,
      getParam,
      // providers,
      setGetParam,
      // providerParam,
      params?.category,
      isContentScrolled,
      location?.pathname,
    ],
  );

  const renderSubCategories = useMemo(
    () => (
      <>
        <SwiperSlide>
          <div
            className={`px-md rowCenter bold-600 gap-5 cursor-pointer ${
              subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                ? 'blueText'
                : 'whiteText'
            }`}
            style={{
              borderRadius: 15,
              backgroundColor:
                subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                  ? 'var(--appYellow)'
                  : '#003069',
              height: 30,
            }}
            onClick={() => {
              getParam.set('subCategoryId', SUB_CATEGORIES_LAST_PLAYED);
              setGetParam(getParam);
            }}>
            <img
              width={10}
              alt={'lastPlayed'}
              src={
                subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                  ? mobileLastPlayedImage
                  : lastPlayedImage
              }
            />
            <span className="font-9 text-uppercase bold-600">
              {t('lastPlayed')}
            </span>
          </div>
        </SwiperSlide>
        {subCategories?.map(subCategoryItem => (
          <SwiperSlide key={subCategoryItem?.name}>
            <div
              key={subCategoryItem?.id}
              className={`px-md rowCenter bold-600 gap-5 cursor-pointer ${
                subCategoryItem?.id === subCategoryIdParam
                  ? 'blueText'
                  : 'whiteText'
              }`}
              style={{
                borderRadius: 15,
                backgroundColor:
                  subCategoryItem?.id === subCategoryIdParam
                    ? 'var(--appYellow)'
                    : '#003069',
                height: 30,
              }}
              onClick={() => {
                getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
                getParam.set('subCategoryId', subCategoryItem?.id);
                setGetParam(getParam);
              }}>
              <img
                width={12}
                alt={subCategoryItem?.name}
                src={require(
                  `../../../Assets/Icons/Casino/${
                    subCategoryItem?.id === subCategoryIdParam ? 'selected' : ''
                  }${
                    subCategoryItem?.name.charAt(0).toUpperCase() +
                    subCategoryItem?.name.slice(1)
                  }.svg`,
                )}
              />
              <span className="font-9 text-uppercase bold-600">
                {subCategoryItem?.title}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </>
    ),
    [getParam, setGetParam, subCategories, subCategoryIdParam, t],
  );

  // useEffect(() => {
  //   setProvidersLoading(true);
  //   if (params?.category === 'slots') {
  //     getProviders({categoryId: subCategoryIdParam})
  //       ?.then(res => {
  //         setProviders(res?.data?.providers);
  //       })
  //       ?.finally(() => {
  //         setTimeout(() => {
  //           setProvidersLoading(false);
  //         }, 300);
  //       });
  //   } else {
  //     getLiveCasinoSubCategories({extra_category_id: subCategoryIdParam})
  //       ?.then(res => {
  //         setProviders(res?.data?.providers);
  //       })
  //       ?.finally(() => {
  //         setTimeout(() => {
  //           setProvidersLoading(false);
  //         }, 300);
  //       });
  //   }
  //   if (!providerParam) {
  //     getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
  //     setGetParam(getParam);
  //   }
  // }, [subCategoryIdParam]);

  useEffect(() => {
    if (params?.category === SKILL_NAME) {
      return;
    }
    setSubCategoriesLoading(true);
    getSubCategories({
      categoryId:
        params?.category === LIVE_CASINO_NAME ? CATEGORY_LIVE_CASINO_ID : '',
    })
      ?.then(res => {
        const filteredSubCategories = filterCasinoSubCategories(
          res?.data?.categories,
          params?.category === LIVE_CASINO_NAME
            ? LIVE_CASINO_FIXED_SUB_CATEGORIES
            : SLOTS_FIXED_SUB_CATEGORIES,
        );
        setSubCategories(filteredSubCategories);
      })
      ?.catch(() => {})
      ?.finally(() => {
        setTimeout(() => {
          setSubCategoriesLoading(false);
        }, 300);
      });
  }, [params?.category]);

  useEffect(() => {
    if (position === 0) {
      document.querySelector('body').classList.add('overflow-hidden');
    } else {
      document.querySelector('body').classList.remove('overflow-hidden');
    }
  }, [position]);

  return (
    <div
      className={`subCategoriesContainer  flex column ${
        width < BREAKPOINT_XS && 'gap-10 mt-sm'
      }`}>
      <div
        className={`overlay absolute absolute-full ${
          position !== 0 && 'hidden'
        }`}></div>
      <div
        onClick={() => setPosition(-width)}
        className="mobileSideBarContainer full-height full-width absolute absolute-full"
        style={{left: `${position}px`}}>
        <div
          style={{width: 'fit-content'}}
          className="fixed"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <SideBarProviders
            isVisible
            closeHandler={() => setPosition(-width)}
          />
        </div>
      </div>
      {width < BREAKPOINT_XS ? (
        <div className="flex rowCenter gap-7">
          <div
            ref={providersContentRef}
            style={{borderRadius: !showProviders && 25}}
            className={`${
              showProviders
                ? 'yellowBackground rounded-top-borders'
                : 'whiteBackground'
            } rowCenter mobileProvidersContainer gap-5 justify-between px-md relative`}
            onClick={() => setPosition(0)}>
            <span className="font-9 bold-600 blueText">{t('Providers')}</span>
            <DownProviders
              width={19}
              height={20}
              style={{transform: showProviders ? 'rotate(180deg)' : ''}}
            />
            {/*{!!showProviders && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      top: 30,*/}
            {/*      left: 0,*/}
            {/*      right: 0,*/}
            {/*      zIndex: 999,*/}
            {/*      minWidth: 280,*/}
            {/*      position: 'absolute',*/}
            {/*      background: '#003069',*/}
            {/*      borderBottomLeftRadius: 10,*/}
            {/*      borderBottomRightRadius: 10,*/}
            {/*      border: '1px solid var(--appYellow)',*/}
            {/*    }}>*/}
            {/*    <div*/}
            {/*      className="gap-5 flex column scroll-3 scroll-auto-y blueBackground"*/}
            {/*      style={{*/}
            {/*        margin: 5,*/}
            {/*        minHeight: 150,*/}
            {/*        maxHeight: 350,*/}
            {/*        borderRadius: 5,*/}
            {/*      }}>*/}
            {/*      {providers?.map(providerItem => (*/}
            {/*        <div*/}
            {/*          key={providerItem?.name}*/}
            {/*          className="px-md py-xs cursor-pointer mr-xs"*/}
            {/*          style={{*/}
            {/*            backgroundColor:*/}
            {/*              providerItem?.name === providerParam*/}
            {/*                ? 'var(--appYellow)'*/}
            {/*                : '#003069',*/}
            {/*            borderRadius: 5,*/}
            {/*          }}*/}
            {/*          onClick={e => {*/}
            {/*            e?.preventDefault?.();*/}
            {/*            e?.stopPropagation?.();*/}
            {/*            getParam.set('provider', providerItem?.name);*/}
            {/*            setGetParam(getParam);*/}
            {/*            setShowProviders(false);*/}
            {/*          }}>*/}
            {/*          <span*/}
            {/*            className={`bold-600 font-12 ${*/}
            {/*              providerItem?.name === providerParam*/}
            {/*                ? 'blueText'*/}
            {/*                : 'whiteText'*/}
            {/*            }`}>*/}
            {/*            {providerItem?.title}*/}
            {/*          </span>*/}
            {/*        </div>*/}
            {/*      ))}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
          <AppButton
            small
            type={5}
            width={65}
            height={30}
            fontSize={9}
            title={t('reset')}
            className="reset rowCenter"
            disabled={
              (!providerParam || providerParam === PROVIDERS_ALL_GAMES_NAME) &&
              !subCategoryIdParam
            }
            onClick={() => {
              getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);
              getParam.delete('subCategoryId');
              setGetParam(getParam);
            }}
          />
          {/*<div*/}
          {/*  className="rowCenter whiteBackground mobileLastPlayed justify-between px-md"*/}
          {/*  onClick={() =>*/}
          {/*    navigate({*/}
          {/*      pathname: `/casino/lastPlayed/${params?.category}`,*/}
          {/*    })*/}
          {/*  }>*/}
          {/*  <img src={mobileLastPlayedImage} alt="lastPlayedIcon" />*/}
          {/*  <span className="bold-600 font-9 text-uppercase">*/}
          {/*    {t('lastPlayed')}*/}
          {/*  </span>*/}
          {/*</div>*/}
          <div
            className="whiteBackground mobileSearchContainer gap-6 rowCenter"
            onClick={() => dispatch(setShowCasinoSearchModal(true))}>
            <SearchIcon width={7} height={7} fill="var(--appBlue)" />
            <span className="bold-600 font-9 blueText">
              {t('searchAnyGame')}
            </span>
          </div>
        </div>
      ) : (
        !location?.pathname?.includes('/casino/poker') && (
          <div
            className={`flex items-center gap-25 mb-xs full-width justify-${
              showCasinoSideBar ? 'end' : 'between'
            }`}>
            {!showCasinoSideBar && (
              <div
                onClick={() => dispatch(setShowCasinoSideBar(true))}
                className="rowCenter cursor-pointer notOpenedRightArrowContainer">
                <RightArrowIcon />
              </div>
            )}
            {/*<div className="flex items-center gap-10">*/}
            {/*  <div className="relative cursor-pointer" ref={providersContentRef}>*/}
            {/*    <div*/}
            {/*      ref={providersContentRef}*/}
            {/*      style={{borderRadius: !showProviders && 25}}*/}
            {/*      className={`${*/}
            {/*        showProviders*/}
            {/*          ? 'yellowBackground rounded-top-borders'*/}
            {/*          : 'whiteBackground'*/}
            {/*      } rowCenter mobileProvidersContainer gap-10 justify-between px-md relative`}*/}
            {/*      onClick={() => setShowProviders(prevState => !prevState)}>*/}
            {/*      <span className="font-12 bold-600 blueText">*/}
            {/*        {t('Providers')}*/}
            {/*      </span>*/}
            {/*      <DownProviders*/}
            {/*        width={19}*/}
            {/*        height={20}*/}
            {/*        style={{transform: showProviders ? 'rotate(180deg)' : ''}}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    {showProviders && (*/}
            {/*      <div*/}
            {/*        className="absolute pa-md row gap-10 wrap"*/}
            {/*        style={{*/}
            {/*          top: 34,*/}
            {/*          zIndex: 10,*/}
            {/*          width: 927,*/}
            {/*          height: 380,*/}
            {/*          background: '#003069',*/}
            {/*          borderTopRightRadius: 10,*/}
            {/*          borderBottomLeftRadius: 10,*/}
            {/*          borderBottomRightRadius: 10,*/}
            {/*          border: '1px solid var(--appYellow)',*/}
            {/*        }}>*/}
            {/*        {providers?.map(providerItem => (*/}
            {/*          <div*/}
            {/*            key={providerItem?.name}*/}
            {/*            className="px-md py-xs cursor-pointer mr-xs "*/}
            {/*            style={{*/}
            {/*              height: 35,*/}
            {/*              backgroundColor:*/}
            {/*                providerItem?.name === providerParam*/}
            {/*                  ? 'var(--appYellow)'*/}
            {/*                  : 'white',*/}
            {/*              borderRadius: 5,*/}
            {/*            }}*/}
            {/*            onClick={e => {*/}
            {/*              e?.preventDefault?.();*/}
            {/*              e?.stopPropagation?.();*/}
            {/*              getParam.set('provider', providerItem?.name);*/}
            {/*              setGetParam(getParam);*/}
            {/*              setShowProviders(false);*/}
            {/*            }}>*/}
            {/*            <img*/}
            {/*              width={150}*/}
            {/*              height={30}*/}
            {/*              alt={providerItem?.name}*/}
            {/*              style={{objectFit: 'contain'}}*/}
            {/*              src={PROVIDER_ICONS?.[providerItem?.name]}*/}
            {/*            />*/}
            {/*          </div>*/}
            {/*        ))}*/}
            {/*      </div>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*  <div*/}
            {/*    style={{width: 170, height: 30, borderRadius: 15}}*/}
            {/*    onClick={() => dispatch(setShowCasinoSearchModal(true))}*/}
            {/*    className="flex rowCenter whiteBackground gap-10 cursor-pointer">*/}
            {/*    <SearchIcon width={15} height={15} fill="var(--appBlue)" />*/}
            {/*    <span className="blueText bold-600">Search any slot</span>*/}
            {/*  </div>*/}
            {/*  <AppButton*/}
            {/*    small*/}
            {/*    type={5}*/}
            {/*    width={70}*/}
            {/*    height={30}*/}
            {/*    className="reset"*/}
            {/*    disabled={*/}
            {/*      (!providerParam ||*/}
            {/*        providerParam === PROVIDERS_ALL_GAMES_NAME) &&*/}
            {/*      !subCategoryIdParam*/}
            {/*    }*/}
            {/*    title={t('reset')}*/}
            {/*    onClick={() => {*/}
            {/*      getParam.set('provider', PROVIDERS_ALL_GAMES_NAME);*/}
            {/*      getParam.delete('subCategoryId');*/}
            {/*      setGetParam(getParam);*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            <div className="row items-center justify-end gap-25 full-width overflow-hidden">
              {subCategoriesLoading ? (
                <div className="overflow-hidden">
                  <PopularCompetitionSkeletons count={3} />
                </div>
              ) : (
                width > BREAKPOINT_XS && (
                  <div
                    className="flex gap-5 overflow-hidden"
                    style={{
                      padding: 1,
                      borderRadius: 25,
                      border: '1px solid var(--appYellow)',
                    }}>
                    <div
                      className={`px-md rowCenter bold-600 gap-5 overflow-hidden cursor-pointer ${
                        subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                          ? 'blueText'
                          : 'whiteText'
                      }`}
                      style={{
                        minWidth: 120,
                        borderRadius: 15,
                        backgroundColor:
                          subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                            ? 'var(--appYellow)'
                            : '#003069',
                        height: 30,
                      }}
                      onClick={() => {
                        if (subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED) {
                          getParam.delete('subCategoryId');
                          setGetParam(getParam);
                        } else {
                          getParam.set(
                            'subCategoryId',
                            SUB_CATEGORIES_LAST_PLAYED,
                          );
                          setGetParam(getParam);
                        }
                      }}>
                      <img
                        width={10}
                        alt={'lastPlayed'}
                        src={
                          subCategoryIdParam === SUB_CATEGORIES_LAST_PLAYED
                            ? mobileLastPlayedImage
                            : lastPlayedImage
                        }
                      />
                      <span className="bold-600 font-12 text-uppercase">
                        {t('lastPlayed')}
                      </span>
                    </div>
                    {params?.category !== SKILL_NAME && (
                      <Swiper
                        freeMode
                        keyboard
                        mousewheel
                        spaceBetween={5}
                        slidesPerView="auto"
                        modules={[Keyboard, FreeMode]}
                        className="homeProvidersSwiperContainer full-width overflow-hidden">
                        {subCategories?.map(subCategoryItem => (
                          <SwiperSlide key={subCategoryItem?.id}>
                            <div
                              className={`px-md rowCenter bold-600 gap-5 cursor-pointer ${
                                subCategoryItem?.id === subCategoryIdParam
                                  ? 'blueText'
                                  : 'whiteText'
                              }`}
                              style={{
                                borderRadius: 15,
                                backgroundColor:
                                  subCategoryItem?.id === subCategoryIdParam
                                    ? 'var(--appYellow)'
                                    : '#003069',
                                height: 30,
                              }}
                              onClick={() => {
                                getParam.set(
                                  'provider',
                                  PROVIDERS_ALL_GAMES_NAME,
                                );
                                if (
                                  subCategoryIdParam === subCategoryItem?.id
                                ) {
                                  getParam.delete('subCategoryId');
                                } else {
                                  getParam.set(
                                    'subCategoryId',
                                    subCategoryItem?.id,
                                  );
                                }
                                setGetParam(getParam);
                              }}>
                              <img
                                width={12}
                                alt={subCategoryItem?.name}
                                src={require(
                                  `../../../Assets/Icons/Casino/${
                                    subCategoryItem?.id === subCategoryIdParam
                                      ? 'selected'
                                      : ''
                                  }${
                                    subCategoryItem?.name
                                      .charAt(0)
                                      .toUpperCase() +
                                    subCategoryItem?.name.slice(1)
                                  }.svg`,
                                )}
                              />
                              <span className="bold-600 font-12 text-uppercase twoLineText">
                                {subCategoryItem?.title}
                              </span>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </div>
                )
              )}
              <div
                className={`whiteBackground ${
                  width > BREAKPOINT_XS ? 'mobileSearchContainer' : ''
                } gap-6 row items-center mobile`}
                onClick={() => dispatch(setShowCasinoSearchModal(true))}>
                <SearchIcon width={10} height={10} fill="var(--appBlue)" />
                <span className="bold-700 font-12 blueText">
                  {t('searchAnyGame')}
                </span>
              </div>
            </div>
          </div>
        )
      )}

      {width < BREAKPOINT_XS && (
        <Swiper
          freeMode
          spaceBetween={5}
          slidesPerView="auto"
          keyboard={width > BREAKPOINT_XS}
          mousewheel={width > BREAKPOINT_XS}
          navigation={width > BREAKPOINT_XS}
          slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}
          slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}
          modules={[
            ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),
            FreeMode,
          ]}
          className={`flex items-center homeProvidersSwiper z-index-2 ${
            params?.category !== SKILL_NAME && 'yellowBorder'
          } ${
            width < BREAKPOINT_XS
              ? `mobile ${params?.category !== SKILL_NAME}`
              : 'desktop'
          }`}
          style={{
            margin: 0,
            padding: 3,
            maxWidth: '100%',
            borderRadius: 25,
            width: 'fit-content',
            border:
              params?.category !== SKILL_NAME
                ? '1px solid var(--appYellow)'
                : 'none',
          }}>
          {renderSubCategories}
        </Swiper>
      )}

      {/*{isSportDataLoading ? (*/}
      {/*  <HomeSportSkeletons isMobile={width < BREAKPOINT_XS} />*/}
      {/*) : providersLoading ? (*/}
      {/*  <></>*/}
      {/*) : providers?.length > 0 ? (*/}
      {/*  <Swiper*/}
      {/*    freeMode*/}
      {/*    spaceBetween={5}*/}
      {/*    slidesPerView="auto"*/}
      {/*    keyboard={width > BREAKPOINT_XS}*/}
      {/*    mousewheel={width > BREAKPOINT_XS}*/}
      {/*    navigation={width > BREAKPOINT_XS}*/}
      {/*    slidesOffsetAfter={width > BREAKPOINT_XS ? 30 : 0}*/}
      {/*    slidesOffsetBefore={width > BREAKPOINT_XS ? 30 : 0}*/}
      {/*    modules={[*/}
      {/*      ...(width > BREAKPOINT_XS ? [Navigation, Keyboard] : []),*/}
      {/*      FreeMode,*/}
      {/*    ]}*/}
      {/*    className={`flex items-center homeProvidersSwiper full-width z-index-2 ${*/}
      {/*      width < BREAKPOINT_XS ? 'mobile blueBackground mb-xl' : 'desktop'*/}
      {/*    }`}>*/}
      {/*    {renderProviders}*/}
      {/*  </Swiper>*/}
      {/*) : (*/}
      {/*  <></>*/}
      {/*)}*/}
    </div>
  );
};

export default HomeProviders;
