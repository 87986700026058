import React, {memo} from 'react';

const CricketSetsScore = ({game}) => {
  const setValues = game?.stats?.[`score_${game?.info?.current_game_state}`];

  return (
    <>
      <div className="rowCenter gap-5">
        <div className="row items-center">
          {game?.info?.pass_team === 'team1' ? (
            <img
              width={9}
              height={9}
              src={require(
                `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
              )}
            />
          ) : (
            <img
              width={9}
              height={9}
              src={
                require(
                  `../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                )?.default
              }
            />
          )}
        </div>
        <div className="row items-center">
          <span className="bold-600 font-12 orangeText">
            {setValues?.team1_value?.split('/')?.[0]}
          </span>
          <span className="bold-600 font-12 orangeText">/</span>
          <span className="bold-600 font-12 blueText">
            {setValues?.team1_value?.split('/')?.[1]}
          </span>
        </div>
      </div>
      <div className="rowCenter gap-5">
        <div className="row items-center">
          {game?.info?.pass_team === 'team2' ? (
            <img
              width={9}
              height={9}
              src={require(
                `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
              )}
            />
          ) : (
            <img
              width={9}
              height={9}
              src={
                require(
                  `../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                )?.default
              }
            />
          )}
        </div>
        <div className="row items-center">
          <span className="bold-600 font-12 orangeText">
            {setValues?.team2_value?.split('/')?.[0]}
          </span>
          <span className="bold-600 font-12 orangeText">/</span>
          <span className="bold-600 font-12 blueText">
            {setValues?.team2_value?.split('/')?.[1]}
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(CricketSetsScore);
