import React, {memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {AppButton} from '../../UI';

import {useScreenSize} from '../../../Hooks';

import {setAuthModal} from '../../../Redux/AppSlice';
import {getIsLoggedIn} from '../../../Redux/AppSlice/AppSelectors';

import {FUN, REAL} from '../../../Constants/Socket';
import {BREAKPOINT_MD, BREAKPOINT_XS} from '../../../Constants/Globals';

const LobbyGameItem = ({
  item,
  activeGame,
  clickHandler,
  setActiveGame,
  setIsGameLoading,
}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);

  const isActive = activeGame === item.id;

  return (
    <div
      onClick={() => {
        if (width < BREAKPOINT_XS) {
          setActiveGame(isActive ? null : item.id);
        }
      }}
      className="buttonContainer cursor-pointer relative"
      style={{
        width: `${(width - 10 - 4 * 5) / 4}px`,
      }}
      onMouseEnter={() => {
        setActiveGame(item.id);
      }}
      onMouseLeave={() => {
        if (width >= BREAKPOINT_XS) setActiveGame(null);
      }}>
      <img alt={item?.id} className="img" src={item?.icon_3} />
      <div
        style={{
          left: 0,
          right: 0,
          bottom: 10,
          position: 'absolute',
          display: isActive ? 'flex' : 'none',
        }}
        className={`flex row items-center px-md gap-5 ${
          width < BREAKPOINT_MD && 'wrap mb-xxs'
        }`}>
        {item?.types?.realMode && (
          <AppButton
            small
            type={3}
            title={t('play')}
            titleClassName="text-capitalize bold-600"
            className={`cursor-pointer`}
            onClick={() => {
              setIsGameLoading(true);
              clickHandler({game: item, mode: REAL});
              if (!isLoggedIn) {
                dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
              }
            }}
          />
        )}
        {item?.types?.funMode && !isLoggedIn && (
          <AppButton
            small
            type={1}
            title={t('demo')}
            titleClassName="bold-600"
            className="cursor-pointer"
            onClick={() => clickHandler({game: item, mode: FUN})}
          />
        )}
      </div>
    </div>
  );
};

export default memo(LobbyGameItem);
