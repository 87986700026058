import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import moment from 'moment';
import './index.scss';

import BetItemRow from './BetItemRow';
import BetSocialShare from '../BetSocialShare';
import CalculationRow from '../CalculationRow';
import {AppButton, AppModal} from '../../../UI';
import CalculationsBlock from '../CalculationsBlock';
import BetSlipSuccessModalSkeletons from '../../../UI/Skeletons/BetslipSuccesModalSkeletons';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {setShowMobileBetSlip} from '../../../../Redux/AppSlice';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getMaxOddForMultipleBet} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {getGameStartTime} from '../../../../Utils/TimeConvertors';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {CHECK_BET_STATUS} from '../../../../Constants/Socket';

import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as ShareBetIcon} from '../../../../Assets/Icons/Globals/shareBet.svg';

const BetSlipSuccessModal = ({betId, isOpen, setIsOpen, isSharedBet}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();
  const {checkBetStatus, subscribeToEvent} = useSocket();

  const shareBetUserParam = useMemo(() => getParam.get('user'), [getParam]);

  const copyTimeoutRef = useRef(null);

  const [data, setData] = useState({});
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const wssSocket = useSelector(getWssSocket);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const Header = useMemo(
    () => (
      <div
        style={{height: 36}}
        className="flex items-center justify-between full-width px-md yellowBackground">
        <ShareBetIcon />
        <span className="font-15 bold-600 blueText text-uppercase">
          {isSharedBet
            ? t('sharedBet', {user: shareBetUserParam})
            : t('shareYourBet')}
        </span>
        <div></div>
      </div>
    ),
    [isSharedBet, shareBetUserParam, t],
  );

  const allEventsData = useMemo(
    () => data?.SelectionList?.map(item => ({price: item?.Price})),
    [data?.SelectionList],
  );

  const oddsAmount = useMemo(() => {
    const calculatedOdds = allEventsData?.reduce(
      (acc, curr) => acc * curr.price,
      1,
    );

    return calculatedOdds > maxOddForMultipleBet
      ? maxOddForMultipleBet
      : +fixedNumberWithoutRound(calculatedOdds);
  }, [allEventsData, maxOddForMultipleBet]);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
    getParam.delete('shareBetId');
    setGetParam(getParam);
  }, [getParam, setGetParam, setIsOpen]);

  const copyIdHandler = useCallback(async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(betId);
    copyTimeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [betId]);

  const addToBetSlip = useCallback(() => {
    data?.SelectionList.forEach((game, index) => {
      subscribeToEvent({
        gameId: game?.MatchId,
        eventId: game?.SelectionId,
      });
      if (index === data?.SelectionList?.length - 1) {
        setTimeout(() => {
          onRequestClose();
          if (width < BREAKPOINT_XS) {
            dispatch(setShowMobileBetSlip(true));
          }
        }, 200);
      }
    });
  }, [data?.SelectionList, subscribeToEvent, onRequestClose, width, dispatch]);

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);
    switch (data?.rid) {
      case CHECK_BET_STATUS:
        setData(data?.data?.details);
        setTimeout(() => {
          setIsLoading(false);
        }, 300);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!!isOpen) {
      setIsLoading(true);
      checkBetStatus({bet_id: betId});
    } else {
      setIsLoading(false);
      setData({});
    }
  }, [isOpen, checkBetStatus]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <AppModal
      isOpen={isOpen}
      header={Header}
      maxWidth="400px"
      closeIconType={3}
      maxHeight="90dvh"
      removeContentPaddings
      onRequestClose={onRequestClose}>
      <div className="flex column items-center justify-between mx-auto full-width newLightBlueBackground betSlipSuccessModalContainer">
        <div className="full-width px-sm mt-sm scroll-auto-y listContainer scroll-5">
          <div className="column my-sm full-width listWrapper">
            {isLoading ? (
              <BetSlipSuccessModalSkeletons count={3} />
            ) : (
              <div className="column rounded-top-borders">
                <div
                  style={{
                    height: 32,
                    border: '1px solid white',
                  }}
                  className="px-md row items-center justify-between rounded-top-borders">
                  <span className="whiteText bold-600 font-10">
                    {getGameStartTime(moment(data?.Created)?.unix?.())}
                  </span>
                  <div className="row items-center gap-7">
                    <span className="whiteText bold-600 font-10">
                      {`${t('betId')} ${betId}`}
                    </span>
                    {isCopied ? (
                      <span className="whiteText bold-600 font-10">
                        {t('copied')}
                      </span>
                    ) : (
                      <div onClick={copyIdHandler} className="cursor-pointer">
                        <CopyIcon width={8} height={8} fill="white" />
                      </div>
                    )}
                  </div>
                </div>
                {data?.SelectionList?.sort(
                  (a, b) =>
                    moment(a?.StartTime)?.unix() - moment(b?.StartTime)?.unix(),
                )?.map((betItem, index) => (
                  <BetItemRow
                    betItem={betItem}
                    key={betItem?.SelectionId}
                    isLast={index === data?.SelectionList?.length - 1}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="pt-md">
            <CalculationRow
              hideCurrency
              amount={data?.Price}
              amountContainerHeight={0}
              title={t('totalOdds')}
              amountTextClassName="font-18"
              titleClassName="whiteText bold-700 font-16"
            />
          </div>
          <CalculationsBlock
            odds={oddsAmount}
            systemBetCount={2}
            betType={data?.TypeName}
            stakeAmount={data?.Amount}
            allEventsData={allEventsData}
          />
        </div>
        <div className="full-width px-sm pt-md pb-sm">
          {isSharedBet ? (
            <div className="full-width">
              <AppButton
                type={3}
                height={40}
                fontSize={15}
                onClick={addToBetSlip}
                title={t('addToMyBetSlip')}
                className="bold-600 row items-center justify-center"
              />
            </div>
          ) : (
            <BetSocialShare
              type={3}
              betId={betId}
              finalPayout={data?.PossibleWin - data?.PossibleTaxAmount}
            />
          )}
        </div>
        {/*{!isSharedBet && (*/}
        {/*  <div*/}
        {/*    className={`flex items-center gap-10 full-width px-sm py-md ${*/}
        {/*      width < 400 ? 'column' : 'row'*/}
        {/*    }`}>*/}
        {/*    <AppButton*/}
        {/*      type={3}*/}
        {/*      title={t('myBets')}*/}
        {/*      onClick={() => {*/}
        {/*        setIsOpen(false);*/}
        {/*        dispatch(setHeaderTabIndex(1));*/}
        {/*        dispatch(setShowMobileBetSlip(true));*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <AppButton*/}
        {/*      type={1}*/}
        {/*      onClick={onRequestClose}*/}
        {/*      title={t('seeMoreOptions')}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    </AppModal>
  );
};

export default memo(BetSlipSuccessModal);
