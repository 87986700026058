import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize} from '../../../Hooks';

import EventItem from './EventItem';

import {ReactComponent as MatchStatsIcon} from '../../../Assets/Icons/Globals/matchStats.svg';
import {ReactComponent as MoreMarketsIcon} from '../../../Assets/Icons/Globals/moreMarkets.svg';
import {useTranslation} from 'react-i18next';

const Events = ({gameItem, displayKey, isSuspended, marketsArray}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const betSlipCount = useSelector(getBetSlipCount);

  const eventsArray = useMemo(
    () => Array.apply(null, Array(marketsArray?.col_count)),
    [marketsArray?.col_count],
  );

  return eventsArray?.map((item, index) => {
    const eventItem = Object.values(marketsArray?.event || {})?.find(
      item => item?.order === index,
    );

    return (
      <div
        className="flex"
        key={!!eventItem ? eventItem?.id : gameItem?.id + index}>
        <div className="columnCenter relative">
          <EventItem
            showBase
            type={4}
            showBoosted
            key={betSlipCount}
            // isFirst={index === 0}
            gameId={gameItem?.id}
            base={eventItem?.base}
            eventId={eventItem?.id}
            displayKey={displayKey}
            price={eventItem?.price}
            isSuspended={isSuspended}
            marketId={marketsArray?.id}
            lastPrice={eventItem?.lastPrice}
            // isLast={index === eventsArray?.length - 1}
          />
        </div>
        {/*{index === eventsArray?.length - 1 && (*/}
        {/*  <div className="column gap-5 justify-center pl-sm">*/}
        {/*    <MoreMarketsIcon />*/}
        {/*    <MatchStatsIcon />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
    );
  });
};
// DO NOT PUT IN MEMO
export default Events;
