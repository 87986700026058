import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {FILTERS_LIVE_NAME} from '../../../Constants/MatchFilters';

const LiveIndicator = ({type = 1}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  return (
    <div
      className={`liveIndicator px-sm py-xxs rounded-borders row items-center justify-center liveIndicator_${type}`}>
      <span className={`liveIndicator_${type}_title`}>
        {t(FILTERS_LIVE_NAME)}
      </span>
    </div>
  );
};

export default memo(LiveIndicator);
