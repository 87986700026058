import React, {memo, useMemo} from 'react';

import './index.scss';

import {
  BET_TYPES,
  SINGLE_BET,
  SYSTEM_BET,
  MAX_SYSTEM_BET_COUNT,
} from '../../../../Constants/Betting';

import {AppSelect} from '../../../UI';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';

const BetTypeDropdown = ({betType, setBetType, allEventsData}) => {
  const availableBettingTypes = useMemo(
    () =>
      BET_TYPES?.filter(item =>
        allEventsData?.length === 1
          ? item?.name === SINGLE_BET
          : (allEventsData?.length < 3 && allEventsData?.length > 0) ||
            allEventsData?.length > MAX_SYSTEM_BET_COUNT
          ? item?.name !== SYSTEM_BET
          : item,
      ),
    [allEventsData?.length],
  );

  return (
    <div className="betTypeDropdownContainer" style={{height: 30}}>
      <AppSelect
        type={8}
        value={betType}
        setValue={setBetType}
        options={availableBettingTypes}
        titleClassName="bold-600 font-12"
        dropDownContentClassName="column gap-5"
        mainContainerClassName="paddingVertical-7 px-md"
        nameClassName="ellipsis-2-lines textCenter font-12"
        itemClassName="bold-400 font-12 paddingVertical-7 px-md justify-center rounded-borders overflow-hidden"
      />
    </div>
  );
};

export default memo(BetTypeDropdown);
