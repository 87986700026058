import React, {
  memo,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import {useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {
  getIsLoggedIn,
  getShowBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {
  getFavouriteCompetitionsObject,
  getIsPopularCompetitionsLoading,
} from '../../../Redux/SportSlice/SportSelectors';

import './AccordionItem.scss';

import {useScreenSize} from '../../../Hooks';

import {ReactComponent as StarIcon} from '../../../Assets/Icons/Globals/whiteStar.svg';
import {ReactComponent as TriangleTopIcon} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangleIcon} from '../../../Assets/Icons/Globals/downTriangle.svg';

import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';
import {
  FOOTBALL_ALIAS,
  MARKETS_TOTALS,
  MARKETS_WINNER,
  MARKETS_ODD_EVEN,
  MARKETS_HANDICAP,
  MARKETS_DOUBLE_CHANCE,
  MARKETS_BOTH_TEAM_TO_SCORE,
} from '../../../Constants/MatchFilters';
import {Flags} from '../../../Constants/Flags';
import {DROPDOWN_MARKETS_NAMES} from '../../../Constants/FixedMarketFiltersArray';

const AccordionItem = ({
  title,
  children,
  GAMES_DATA,
  region_alias,
  competition_id,
  onFavouriteSelect,
  hideResultNames = false,
  defaultExpanded = false,
}) => {
  const params = useParams();
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();

  const getMarketParam = getParam.get('market');
  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );

  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const isPopularCompetitionsLoading = useSelector(
    getIsPopularCompetitionsLoading,
  );
  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const [expanded, setExpanded] = useState(defaultExpanded);
  const [height, setHeight] = useState(
    document?.querySelector?.('.sportsWrapper')?.offsetHeight +
      document?.querySelector?.('.groupByCompetitionContainer')?.offsetHeight,
  );

  const isFootball = useMemo(
    () => getParam.get('sport') === FOOTBALL_ALIAS,
    [getParam],
  );

  const TriangleIcon = useMemo(
    () => (expanded ? TriangleTopIcon : DownTriangleIcon),
    [expanded],
  );

  useLayoutEffect(() => {
    if (!isPopularCompetitionsLoading) {
      setHeight(
        document?.querySelector?.('.sportsWrapper')?.offsetHeight +
          document?.querySelector?.('.groupByCompetitionContainer')
            ?.offsetHeight,
      );
    }
  }, [width, isPopularCompetitionsLoading]);

  const getResultName = useCallback(
    displayKey => {
      let colCount = null;
      if (
        displayKey === MARKETS_TOTALS ||
        displayKey === MARKETS_HANDICAP ||
        displayKey === MARKETS_ODD_EVEN ||
        displayKey === MARKETS_BOTH_TEAM_TO_SCORE
      ) {
        colCount = 2;
      }
      for (let i = 0; i < GAMES_DATA?.length; i++) {
        if (!!colCount) {
          break;
        }

        const market = Object.values(GAMES_DATA[i]?.market || {})?.find(
          marketItem => marketItem?.display_key === displayKey,
        );

        if (!!market?.col_count) {
          colCount = market?.col_count;
        }
      }
      // TODO it's written without map because the names Pano wants to change every time
      return (
        <div className="justify-end flex items-center full-height font-11">
          {displayKey === MARKETS_WINNER ? (
            colCount === 2 ? (
              <div className="flex column items-center">
                <div
                  // style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                  className="flex justify-between gap-5">
                  <span
                    className="text-center  whiteText bold-600"
                    style={{width: 45}}>
                    1
                  </span>
                  <span
                    className="text-center  whiteText bold-600"
                    style={{width: 45}}>
                    2
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex column items-center">
                <div
                  // style={{width: width < BREAKPOINT_XS ? 165 : 190}}
                  className="flex justify-between gap-5">
                  <span
                    className="text-center  whiteText bold-600"
                    style={{width: 45}}>
                    1
                  </span>
                  <span
                    className="text-center  whiteText bold-600"
                    style={{width: 45}}>
                    X
                  </span>
                  <span
                    className="text-center  whiteText bold-600"
                    style={{width: 45}}>
                    2
                  </span>
                </div>
              </div>
            )
          ) : displayKey === MARKETS_BOTH_TEAM_TO_SCORE ? (
            <div
              className="flex column items-center"

              // style={{maxWidth: 125}}
            >
              <div
                // style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('YES')}
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : displayKey === MARKETS_DOUBLE_CHANCE ? (
            <div className="flex column items-center">
              <div
                // style={{width: width < BREAKPOINT_XS ? 175 : 190}}
                className="flex justify-between gap-5">
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  1 X
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  1 2
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  2 X
                </span>
              </div>
            </div>
          ) : displayKey === MARKETS_HANDICAP ? (
            <div className="flex column items-center">
              <div
                // style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('YES')}
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : displayKey === MARKETS_TOTALS ? (
            <div className="flex column items-center">
              <div
                // style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('OVER')}
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('UNDER')}
                </span>
              </div>
            </div>
          ) : MARKETS_ODD_EVEN ? (
            <div className="flex column items-center">
              <div
                // style={{width: width < BREAKPOINT_XS ? 110 : 125}}
                className="flex justify-between gap-5">
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('YES')}
                </span>
                <span
                  className="text-center  whiteText bold-600"
                  style={{width: 45}}>
                  {t('NO')}
                </span>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
    [GAMES_DATA, t, width],
  );

  const marketsData = useMemo(
    () =>
      DROPDOWN_MARKETS_NAMES?.[sportParam] ||
      DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'],
    [sportParam],
  );

  return (
    <div
      style={{border: '1px solid white'}}
      className="accordionItemContainer relative rounded-borders">
      <div
        onClick={() => setExpanded(prevState => !prevState)}
        // style={{top: !!sportGameIdParam ? 35 : (height || 180) - 2}}
        style={{
          top: document.querySelector('.groupByCompetitionContainer')
            ?.offsetHeight,
          backgroundColor: '#003069',
        }}
        className={`headerContainer row justify-between px-sm items-center rounded-borders whiteText py-md ${
          width < BREAKPOINT_XS ? 'mobile' : 'desktop'
        }`}>
        <div
          style={{flexGrow: 1}}
          className="row items-center gap-9 overflow-hidden">
          <div className={`row items-center gap-7 ${!isLoggedIn && 'px-xs'}`}>
            {!!isLoggedIn && (
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onFavouriteSelect(competition_id);
                }}>
                <StarIcon
                  fill={
                    Object.keys(favouriteCompetitionsObject || {})?.includes(
                      `${competition_id}`,
                    )
                      ? 'var(--appYellow)'
                      : 'transparent'
                  }
                />
              </div>
            )}
            {Flags?.[region_alias] && (
              <img
                alt="flag"
                width={15}
                height={10}
                src={Flags?.[region_alias]}
                style={{objectFit: 'cover'}}
              />
            )}
          </div>
          <span
            className="font-12 bold-700 ellipsis"
            // style={{maxWidth: width / 2 - 70}}
          >
            {title}
          </span>
        </div>
        {!hideResultNames && (
          <div className="row gap-30" style={{paddingRight: 13}}>
            {marketsData?.map((item, index) => (
              <div
                className={`sm-hide ${!!getMarketParam && 'hide'} ${
                  width < (showBetSlip ? 1640 : 1320) &&
                  marketsData?.length > 3 &&
                  index === marketsData?.length - 1 &&
                  'hide'
                } ${
                  width < 1200 &&
                  marketsData?.length > 3 &&
                  index === marketsData?.length - 2 &&
                  'hide'
                } ${showBetSlip && width < 1450 && index > 2 && 'hide'} ${
                  showBetSlip && width < 1330 && index > 1 && 'hide'
                } ${showBetSlip && width < 1200 && index > 0 && 'hide'}`}
                key={item?.title}>
                {getResultName(item?.displayKey)}
              </div>
            ))}
            {/*<div className={`sm-hide ${!!getMarketParam && 'hide'}`}>*/}
            {/*  {getResultName(MARKETS_WINNER)}*/}
            {/*</div>*/}
            {/*{!!isFootball && (*/}
            {/*  <div*/}
            {/*    className={`sm-hide ${*/}
            {/*      (!!getMarketParam ||*/}
            {/*        (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
            {/*        (!!showBetSlip &&*/}
            {/*          width >= BREAKPOINT_XS &&*/}
            {/*          width < BREAKPOINT_MD)) &&*/}
            {/*      'hide'*/}
            {/*    }`}>*/}
            {/*    {getResultName(MARKETS_DOUBLE_CHANCE)}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*{!!isFootball && (*/}
            {/*  <div*/}
            {/*    className={`sm-hide ${*/}
            {/*      (!!getMarketParam ||*/}
            {/*        (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
            {/*        (!!showBetSlip &&*/}
            {/*          width >= BREAKPOINT_XS &&*/}
            {/*          width < BREAKPOINT_LG)) &&*/}
            {/*      'hide'*/}
            {/*    }`}>*/}
            {/*    {getResultName(MARKETS_BOTH_TEAM_TO_SCORE)}*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*<div*/}
            {/*  className={`sm-hide ${*/}
            {/*    (!!getMarketParam ||*/}
            {/*      (!!sportGameIdParam && width >= BREAKPOINT_MD) ||*/}
            {/*      (!!showBetSlip &&*/}
            {/*        !!isFootball &&*/}
            {/*        width >= BREAKPOINT_XS &&*/}
            {/*        width < BREAKPOINT_LG)) &&*/}
            {/*    'hide'*/}
            {/*  }`}>*/}
            {/*  {getResultName(MARKETS_HANDICAP)}*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={`md-hide sm-hide ${*/}
            {/*    (!!getMarketParam ||*/}
            {/*      (!!sportGameIdParam && width > BREAKPOINT_MD) ||*/}
            {/*      (!!showBetSlip &&*/}
            {/*        width >= BREAKPOINT_XS &&*/}
            {/*        width < BREAKPOINT_LG)) &&*/}
            {/*    'hide'*/}
            {/*  }`}>*/}
            {/*  {getResultName(MARKETS_TOTALS)}*/}
            {/*</div>*/}
          </div>
        )}
        <div
          className={`${!getMarketParam && 'hide'}`}
          style={{paddingRight: 13}}>
          {/*{*/}
          {/*  console*/}
          {/*    .log(*/}
          {/*      DROPDOWN_MARKETS_NAMES?.[getParam.get('sport')] ||*/}
          {/*        DROPDOWN_MARKETS_NAMES?.OTHER_SPORTS_MARKETS,*/}
          {/*    )*/}
          {/*    ?.[getParam.get('sport')]?.find(*/}
          {/*      item => item?.title === getMarketParam,*/}
          {/*    )*/}
          {/*    ?.find(item => item?.title === getMarketParam)?.displayKey*/}
          {/*}*/}
          {getResultName(
            marketsData?.find(item => item?.title === getMarketParam)
              ?.displayKey,
          )}
        </div>
        {/*{width < BREAKPOINT_XS && (*/}
        {/*  <div className="column gap-5 justify-center pl-sm">*/}
        {/*    <TriangleIcon width={11} height={10} fill="var(--gray)" />*/}
        {/*  </div>*/}
        {/*)}*/}
      </div>
      <div className={`${expanded ? 'block' : 'hidden'}`}>{children}</div>
    </div>
  );
};

export default memo(AccordionItem);
