import React, {memo, useEffect, useState} from 'react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useParams, useSearchParams} from 'react-router-dom';

import './index.scss';

import {useScreenSize} from '../../../Hooks';

import {AppButton, AppSelect} from '../../UI';

import {FOOTBALL_ALIAS} from '../../../Constants/MatchFilters';
import {DROPDOWN_MARKETS_NAMES} from '../../../Constants/FixedMarketFiltersArray';
import {BREAKPOINT_LG, BREAKPOINT_XS} from '../../../Constants/Globals';

const FixedMarketsFilters = ({setIsGrouped, isGrouped}) => {
  const params = useParams();
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const getDayParam = getParam.get('day');
  const getMarketParam = getParam.get('market');
  const getSportGameIdParam = getParam.get('sportGameId');

  useEffect(() => {
    if (
      (!getMarketParam && width < BREAKPOINT_XS) ||
      (!getMarketParam && width > BREAKPOINT_XS && !!getSportGameIdParam)
    ) {
      getParam.set(
        'market',
        (DROPDOWN_MARKETS_NAMES?.[getParam.get('sport')] ||
          DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'])?.[0]?.title,
      );
      getParam.set(
        'marketFilter',
        (DROPDOWN_MARKETS_NAMES?.[getParam.get('sport')] ||
          DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'])?.[0]?.subTitle,
      );
      setGetParam(getParam);
    }
  }, [getMarketParam, getParam, getSportGameIdParam, setGetParam, width]);

  return (
    (!getSportGameIdParam ||
      width > BREAKPOINT_LG ||
      (!!getSportGameIdParam && width > BREAKPOINT_XS)) && (
      <div className="fixedMarketsFiltersContainer row full-width">
        {/*<Swiper*/}
        {/*  freeMode*/}
        {/*  keyboard*/}
        {/*  mousewheel*/}
        {/*  spaceBetween={5}*/}
        {/*  slidesPerView="auto"*/}
        {/*  modules={[Keyboard, FreeMode]}*/}
        {/*  className="fixedMarketsFiltersSwiper full-width">*/}
        {/*  {(*/}
        {/*    (getParam.get('sport') === FOOTBALL_ALIAS*/}
        {/*      ? FIXED_FOOTBALL_MARKETS_FILTERS*/}
        {/*      : FIXED_MARKETS_FILTERS) || []*/}
        {/*  )?.map(item => (*/}
        {/*    <SwiperSlide key={item?.title}>*/}
        {/*      <AppButton*/}
        {/*        type={15}*/}
        {/*        className="py-xs px-sm"*/}
        {/*        active={getMarketParam === item?.title}*/}
        {/*        title={*/}
        {/*          <span className="text-caption-small blackText bold-500">*/}
        {/*            {t(item?.title)}*/}
        {/*          </span>*/}
        {/*        }*/}
        {/*        onClick={() => {*/}
        {/*          getParam.set('market', item?.title);*/}

        {/*          setGetParam(getParam);*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    </SwiperSlide>*/}
        {/*  ))}*/}
        {/*</Swiper>*/}
        <AppSelect
          type={7}
          value={t(getMarketParam)}
          onItemClickCb={item => {
            getParam.set('market', item?.title);
            getParam.set('marketFilter', item?.subTitle);
            setGetParam(getParam);
          }}
          titleClassName="bold-600 font-10"
          dropDownContentClassName="column gap-5"
          nameClassName="ellipsis-2-lines textCenter"
          mainContainerClassName={`paddingHorizontal-6 selectContainer ${
            width < BREAKPOINT_XS ? 'mobile' : 'desktop'
          }`}
          itemClassName="bold-400 font-12 py-md px-lg justify-center rounded-borders overflow-hidden"
          options={
            DROPDOWN_MARKETS_NAMES?.[getParam.get('sport')] ||
            DROPDOWN_MARKETS_NAMES?.['OTHER_SPORTS_MARKETS']
          }
        />
      </div>
    )
  );
};

export default memo(FixedMarketsFilters);
