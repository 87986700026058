import React, {memo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useSearchParams} from 'react-router-dom';

import {setAuthModal} from '../../Redux/AppSlice';
import {getAuthModal, getIsLoggedIn} from '../../Redux/AppSlice/AppSelectors';

import SignIn from './SignIn';
import Register from './Register';
import ForgotPassword from './ForgotPassword';

import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../Constants/Globals';

import {AppModal} from '../UI';

const AuthStack = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [getParam, setGetParam] = useSearchParams();

  const authModal = useSelector(getAuthModal);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const onRequestClose = useCallback(() => {
    dispatch(setAuthModal({isVisible: false}));
    setTimeout(() => {
      dispatch(setAuthModal({tabIndex: 0}));
    }, APP_MODAL_CLOSE_TIMEOUT_MS);
    if (location?.pathname?.includes('/casino') && !isLoggedIn) {
      getParam.delete('casinoGameId');
      getParam.delete('mode');
      getParam.delete('search');
      setGetParam(getParam);
    }
  }, [dispatch, getParam, isLoggedIn, location?.pathname, setGetParam]);

  return (
    <AppModal
      maxWidth="450px"
      isOpen={authModal?.isVisible}
      onRequestClose={onRequestClose}
      isCloseDisabled={authModal.isCloseDisabled}>
      {authModal?.tabIndex === 0 ? (
        <SignIn />
      ) : authModal?.tabIndex === 1 ? (
        <Register />
      ) : authModal?.tabIndex === 2 ? (
        <ForgotPassword />
      ) : null}
    </AppModal>
  );
};

export default memo(AuthStack);
