import React, {useMemo} from 'react';

import Scoring from '../../Scoring';
import StartTime from '../../Scoring/StartTime';

const Header = ({game}) => {
  const showGamePassIcon = useMemo(
    () =>
      (game?.sport_alias === 'Tennis' ||
        game?.sport_alias === 'Volleyball' ||
        game?.sport_alias === 'TableTennis' ||
        game?.sport_alias === 'Cricket') &&
      !!game?.is_live,
    [game?.is_live, game?.sport_alias],
  );

  return (
    <div className={`headerContainer`}>
      <div className={`flex row  items-center full-width`}>
        {game?.show_type === 'OUTRIGHT' ? (
          <div
            className="full-width columnCenter"
            style={{padding: '14px 0px'}}>
            <img
              width={62}
              height={62}
              alt={game?.team1_id}
              style={{borderRadius: 62}}
              src={`https://statistics.bcapps.site/images/e/b/${Math.floor(
                game?.team1_id / 2000,
              )}/${game?.team1_id}.png`}
            />
            <span
              className="text-center full-width whiteText font-10 bold-500"
              style={{maxWidth: 100, marginTop: 10}}>
              {game?.team1_name}
            </span>
            <StartTime game={game} />
          </div>
        ) : (
          <div
            className={`full-width row justify-between gap-10`}
            style={{padding: `15px ${!showGamePassIcon ? '13px' : '0'}`}}>
            <div className="row items-center full-width">
              {showGamePassIcon && (
                <>
                  {game?.info?.pass_team === 'team1' ? (
                    <img
                      width={15}
                      height={15}
                      style={{marginBottom: 31}}
                      src={require(
                        `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
                      )}
                    />
                  ) : game?.sport_alias === 'Cricket' &&
                    game?.info?.pass_team !== 'team1' ? (
                    <img
                      width={15}
                      height={15}
                      style={{marginBottom: 31}}
                      src={
                        require(
                          `../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                        )?.default
                      }
                    />
                  ) : (
                    <div style={{width: 15}}></div>
                  )}
                </>
              )}
              {game?.team1_name && (
                <div
                  className="column items-center justify-center"
                  style={{marginLeft: 5}}>
                  <img
                    width={62}
                    height={62}
                    alt={game?.team1_id}
                    style={{borderRadius: 62}}
                    src={`https://statistics.bcapps.site/images/e/b/${Math.floor(
                      game?.team1_id / 2000,
                    )}/${game?.team1_id}.png`}
                  />
                  <span
                    className="text-center full-width whiteText font-12 bold-500 teamName ellipsis-2-lines"
                    style={{marginTop: 9}}>
                    {game?.team1_name}
                  </span>
                </div>
              )}
            </div>
            <div className="flex row gap-5 full-width justify-center text-center">
              <Scoring game={game} isGlobalScore />
            </div>
            <div className="row items-center full-width justify-end">
              {game?.team2_name && (
                <div
                  className="column items-center justify-center"
                  style={{marginRight: 5}}>
                  <img
                    width={62}
                    height={62}
                    alt={game?.team2_id}
                    style={{borderRadius: 62}}
                    src={`https://statistics.bcapps.site/images/e/b/${Math.floor(
                      game?.team2_id / 2000,
                    )}/${game?.team2_id}.png`}
                  />
                  <span
                    className="text-center  full-width whiteText font-12 bold-500 teamName ellipsis-2-lines"
                    style={{marginTop: 9}}>
                    {game?.team2_name}
                  </span>
                </div>
              )}
              {showGamePassIcon && (
                <>
                  {game?.info?.pass_team === 'team2' ? (
                    <img
                      width={15}
                      height={15}
                      style={{marginBottom: 31}}
                      src={require(
                        `../../../Assets/Icons/SingleGameActiveTeam/${game?.sport_alias}.svg`,
                      )}
                    />
                  ) : game?.sport_alias === 'Cricket' &&
                    game?.info?.pass_team !== 'team2' ? (
                    <img
                      width={15}
                      height={15}
                      style={{marginBottom: 31}}
                      src={
                        require(
                          `../../../Assets/Icons/SingleGameActiveTeam/CricketBall.svg`,
                        )?.default
                      }
                    />
                  ) : (
                    <div style={{width: 15}}></div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
