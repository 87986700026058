import {memo} from 'react';
import Skeleton from 'react-loading-skeleton';

const GameSkeletons = () => (
  <Skeleton
    count={7}
    height={80}
    width="100%"
    duration={0.7}
    className="top-5"
    borderRadius="5px"
    baseColor="var(--lightBlue)"
    highlightColor="var(--appBlue)"
  />
);

export default memo(GameSkeletons);
