import React from 'react';

import StartTime from '../StartTime';

const CricketGlobalScore = ({game}) => {
  const setValues = game?.stats?.[`score_${game?.info?.current_game_state}`];

  return (
    <div
      className={`flex column items-center justify-${
        !!game?.is_live ? 'center' : 'between'
      }`}>
      <StartTime game={game} />
      <div className="flex column justify-center gap-5">
        {!!game?.is_live && (
          <div>
            <span className="bold-500 font-30 orangeText">
              {setValues?.[`${game?.info?.pass_team}_value`]?.split('/')?.[0]}
            </span>
            <span className="bold-500 font-30 orangeText">/</span>
            <span className="bold-500 font-30 whiteText">
              {setValues?.[`${game?.info?.pass_team}_value`]?.split('/')?.[1]}
            </span>
          </div>
        )}
        {!!game?.is_live && (
          <span className="bold-600 font-12 orangeText">
            ({game?.stats?.over?.[`${game?.info?.pass_team}_value`]} ov)
          </span>
        )}
      </div>
      {!!game?.is_live && game?.info?.current_game_state !== 'notstarted' && (
        <div className="flex gap-5">
          {game?.info?.current_game_time && (
            <span className="whiteText bold-600 font-14">
              {game?.info?.current_game_time}'
            </span>
          )}
        </div>
      )}
      {!game?.is_live && <div></div>}
    </div>
  );
};

export default CricketGlobalScore;
