import React, {memo, useCallback, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import './index.scss';

import {useOutSideClickHandler} from '../../../Hooks';

import success from '../../../Assets/Icons/Globals/success.svg';
import {ReactComponent as ArrowUpIcon} from '../../../Assets/Icons/Globals/arrowUp.svg';
import {ReactComponent as ArrowDownIcon} from '../../../Assets/Icons/Globals/arrowDown.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downTriangle.svg';
import {ReactComponent as RoundedTriangleTop} from '../../../Assets/Icons/Globals/roundedTriangleTop.svg';
import {ReactComponent as RoundedTriangleBottom} from '../../../Assets/Icons/Globals/roundedTringleBottom.svg';

const Select = ({
  value,
  options,
  checked,
  setValue,
  disabled,
  type = 1,
  titleStyle = {},
  className = null,
  nameClassName = '',
  onItemClickCb = null,
  itemClassName = null,
  titleClassName = null,
  dropDownContentClassName = '',
  mainContainerClassName = null,
}) => {
  const {t} = useTranslation();

  const appSelectRef = useRef(null);

  useOutSideClickHandler(appSelectRef, () => setIsActive(false));

  const [isActive, setIsActive] = useState(false);

  const foundOption = useMemo(
    () => options?.find(item => item?.value === value),
    [options, value],
  );

  const onItemClick = useCallback(
    item => {
      if (typeof onItemClickCb === 'function') {
        onItemClickCb(item);
      } else {
        setValue(item?.value);
      }
      setIsActive(!isActive);
    },
    [isActive, onItemClickCb, setValue],
  );

  const ArrowIcon = isActive ? ArrowUpIcon : ArrowDownIcon;

  const TriangleIcon = isActive
    ? type === 1 || type === 7 || type === 8
      ? RoundedTriangleTop
      : TopTriangle
    : type === 1 || type === 7 || type === 8
    ? RoundedTriangleBottom
    : DownTriangle;

  return (
    <div
      ref={appSelectRef}
      className={`appSelect relative full-width ${className ? className : ''}`}>
      <div
        className={`
          dropdown-btn ${
            isActive
              ? `rounded-top-borders dropdown-btn_${type}_active`
              : 'rounded-borders'
          } flex dropdown-btn_${type} row items-center justify-between ${
            !!mainContainerClassName
              ? mainContainerClassName
              : type === 1 || type === 7 || type === 8
              ? 'pa-md'
              : 'px-md py-sm'
          } full-width full-height ${disabled ? 'disabled' : ''}
        `}
        onClick={() =>
          disabled ? () => {} : setIsActive(prevState => !prevState)
        }>
        <div className="flex row items-center gap-5">
          {foundOption?.logo && (
            <img
              width={25}
              height={15}
              alt={foundOption?.logo}
              src={foundOption?.logo}
            />
          )}
          <span
            style={titleStyle}
            className={`${
              disabled
                ? 'blueText'
                : type === 1 ||
                  type === 7 ||
                  type === 8 ||
                  type === 5 ||
                  type === 6
                ? isActive && (type === 1 || type === 7 || type === 8)
                  ? 'blueText bold-600'
                  : 'whiteText'
                : 'blueText'
            }  ${titleClassName ? titleClassName : 'font-12 bold-600'}`}>
            {foundOption?.name ? t(foundOption?.name) : value}
          </span>
        </div>

        {!disabled && type !== 4 && type !== 5 && (
          <div
            className="flex items-center"
            onClick={e => {
              e?.preventDefault?.();
              e?.stopPropagation?.();
              setIsActive(prevState => !prevState);
            }}>
            <TriangleIcon
              width={type === 1 || type === 7 || type === 8 ? 19 : 10}
              height={type === 1 || type === 7 || type === 8 ? 19 : 10}
              fill={
                type === 1 || type === 7 || type === 8
                  ? 'var(--white)'
                  : 'var(--appBlue)'
              }
            />
          </div>
        )}

        {!disabled && type === 4 && (
          <div
            style={{width: 15, height: 15, borderRadius: '50%'}}
            className={`darkBlueBackground row items-center justify-center`}>
            <ArrowIcon width={9} fill="var(--white)" />
          </div>
        )}
        {!disabled && type === 5 && (
          <div
            style={{width: 20, height: 20, borderRadius: '50%'}}
            className={`whiteBackground row items-center justify-center`}>
            <ArrowIcon width={9} fill="var(--appBlue)" />
          </div>
        )}
        {!disabled && type === 6 && (
          <div
            style={{width: 20, height: 20, borderRadius: '50%'}}
            className={`whiteBackground row items-center justify-center`}>
            <ArrowIcon width={9} fill="var(--appBlue)" />
          </div>
        )}
      </div>
      {isActive && (
        <div
          className={`dropdown-content dropdown-content_${type} full-width absolute dropdown-content_${type}_yellowContainer overflow-auto scroll-3`}>
          <div
            className={`scroll-y scroll-${
              type === 1 || type === 7 || type === 8 ? 3 : 1
            } dropdown-content_${type}_yellowContainer_wrapper ${dropDownContentClassName}`}>
            {options?.map((item, index) => (
              <div
                key={`${item?.value}-${item?.name || index}`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onItemClick(item);
                }}
                className={`item ${
                  !!itemClassName
                    ? itemClassName
                    : type === 1 || type === 7 || type === 8
                    ? 'py-md rowCenter rounded-borders'
                    : 'px-md py-sm'
                } flex row ${
                  type !== 3 ? 'font-12' : 'font-14 mx-md text-center'
                } items-center gap-5 ${
                  type !== 3
                    ? item?.value === value
                      ? 'selected'
                      : 'no-selected'
                    : ''
                } `}
                style={{
                  borderBottom:
                    type === 1 || type === 7 || type === 8
                      ? 'none'
                      : index !== options?.length - 1
                      ? '1px solid #D9DEE4'
                      : '',
                }}>
                {item?.logo && (
                  <img
                    alt={item?.logo}
                    src={item?.logo}
                    width={25}
                    height={15}
                  />
                )}
                <span className={nameClassName}>{t(item?.name)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {checked && <img alt="success" src={success} className="checkedIcon" />}
    </div>
  );
};

export default memo(Select);
