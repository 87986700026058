import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  providers: [],
  showCasinoSideBar: true,
  showCasinoSearchModal: false,
};

export const casinoSlice = createSlice({
  name: 'casino',
  initialState,
  reducers: {
    setProviders: (state, {payload}) => {
      state.providers = payload;
    },
    setShowCasinoSearchModal: (state, {payload}) => {
      state.showCasinoSearchModal = payload;
    },
    setShowCasinoSideBar: (state, {payload}) => {
      state.showCasinoSideBar = payload;
    },
  },
});

export const {setProviders, setShowCasinoSearchModal, setShowCasinoSideBar} =
  casinoSlice.actions;

export default casinoSlice.reducer;
