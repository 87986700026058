import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import './index.scss';

import {setAuthModal, setMainMenuModal} from '../../../../../Redux/AppSlice';

import {AppButton} from '../../../../UI';

import {APP_MODAL_CLOSE_TIMEOUT_MS} from '../../../../../Constants/Globals';

import congrats from '../../../../../Assets/Layout/congrats.png';

const Congrats = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className="congratsWrapper flex column items-center gap-10 congratsWrapper py-lg">
      <div className="flex column items-center gap-10">
        <span className="blueText font-23 bold-700">
          {t('congratulations')}
        </span>
        <span className="blueText font-13">{t('youAreRegistered')}</span>
      </div>
      <img src={congrats} alt="congrats" width="100%" />

      <span className="blueText font-13">{t('pleaseDeposit')}</span>
      <AppButton
        type={3}
        title={t('depositNow')}
        onClick={() => {
          dispatch(setAuthModal({isVisible: false}));
          setTimeout(
            () =>
              dispatch(
                setMainMenuModal({
                  tabIndex: 0,
                  isDesktop: true,
                  isVisible: true,
                  balanceManagementTabIndex: 0,
                }),
              ),
            APP_MODAL_CLOSE_TIMEOUT_MS,
          );
        }}
      />
      <AppButton
        type={1}
        title={t('continue')}
        onClick={() => dispatch(setAuthModal({isVisible: false}))}
      />
    </div>
  );
};

export default memo(Congrats);
