import React, {memo, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import './index.scss';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {getBonusInfoData} from '../../../../Redux/BettingSlice/BettingSelectors';

import {ReactComponent as BonusInfoIcon} from '../../../../Assets/Icons/Globals/info.svg';

import {AppModal} from '../../../UI';

const BonusInfo = () => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);
  const bonusInfoData = useSelector(getBonusInfoData);

  const [isOpen, setIsOpen] = useState(false);

  const onRequestClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const Header = useMemo(
    () => (
      <span className="py-md text-subtitle2 blueText bold-600">
        {t('accumulatorBonus')}
      </span>
    ),
    [t],
  );

  return (
    <>
      <div
        className="row items-center gap-5 cursor-pointer"
        onClick={() => setIsOpen(true)}>
        <BonusInfoIcon fill="var(--white)" width={15} height={15} />
        <span className="font-12 whiteText">{t('bonusInfo')}</span>
      </div>
      <AppModal
        isOpen={isOpen}
        header={Header}
        maxWidth="500px"
        onRequestClose={onRequestClose}>
        <div className="column full-width gap-5 rounded-borders py-md accumulatorBonusInfoModal">
          <div className="row items-center font-13 bold-600 overflow-hidden">
            <span className="col-xxs-3 flex justify-center lightGrayText ellipsis block textCenter">
              {t('count')}
            </span>
            <span className="col-xxs-3 flex justify-center lightGrayText ellipsis block textCenter">
              {t('minOdds')}
            </span>
            <span className="col-xxs-4 flex justify-center lightGrayText ellipsis block textCenter">
              {t('minStake')}
            </span>
            <span className="col-xxs-2 flex justify-center yellowText">%</span>
          </div>
          {bonusInfoData?.map((item, index) => (
            <div
              key={`${item?.AmountPercent}_${index}`}
              className="row items-center justify-between items-center text-body2 bold-500">
              <span className="col-xxs-3 flex justify-center lightGrayText">
                {item?.MinimumSelections}
              </span>
              <span className="col-xxs-3 flex justify-center lightGrayText">
                {item?.MinOdds}
              </span>
              <span className="col-xxs-4 flex justify-center lightGrayText">
                {
                  item?.MinBetStakes?.MinStakes?.find(
                    item => item?.Currency === currency,
                  )?.Amount
                }
              </span>
              <span className="col-xxs-2 flex justify-center yellowText">
                {item?.AmountPercent}
              </span>
            </div>
          ))}
        </div>
      </AppModal>
    </>
  );
};

export default memo(BonusInfo);
