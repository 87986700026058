import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {AppButton, AppError, AppInput} from '../../../UI';

import {getUser} from '../../../../Redux/UserSlice/UserSelectors';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {
  PHONE_CODE,
  PHONE_NUMBER_PREFIX,
  PHONE_NUMBER_PLACEHOLDER,
} from '../../../../Constants/Globals';
import {BUDDY_TO_BUDDY_TRANSFER} from '../../../../Constants/Socket';
import {PROFILE} from '../../../../Constants/MainMenu';

const SendToFriend = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {buddyToBuddyTransfer} = useSocket();

  const user = useSelector(getUser);
  const wssSocket = useSelector(getWssSocket);
  const currency = useSelector(getCurrency) || 'TZS';

  const [errorMsg, setErrorMsg] = useState(null);
  const [sendAmount, setSendAmount] = useState('');
  const [friendPhone, setFriendPhone] = useState('');
  const [isSendLoading, setIsSendLoading] = useState(false);

  const friendPhoneErrorMsg = useMemo(
    () => friendPhone && friendPhone.length !== 9 && t('phoneErrorMsg'),
    [friendPhone, t],
  );

  const isSendDisabled = useMemo(
    () =>
      !user?.is_verified ||
      !sendAmount?.trim?.() ||
      !friendPhone?.trim?.() ||
      !!errorMsg ||
      !!friendPhoneErrorMsg ||
      !user?.balance ||
      (!!user?.balance && user?.balance < +sendAmount) ||
      +sendAmount < 1000 ||
      +sendAmount > 5000000,
    [
      errorMsg,
      sendAmount,
      friendPhone,
      user?.balance,
      user?.is_verified,
      friendPhoneErrorMsg,
    ],
  );

  const sendPressHandler = useCallback(() => {
    setIsSendLoading(true);
    buddyToBuddyTransfer({
      amount: sendAmount,
      to_user: `${PHONE_CODE}${friendPhone}`,
    });
  }, [buddyToBuddyTransfer, friendPhone, sendAmount]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case BUDDY_TO_BUDDY_TRANSFER:
          if (data?.data?.result === 0) {
            dispatch(
              setMainMenuModal({
                isVisible: false,
              }),
            );
            dispatch(
              setSuccessModal({
                isVisible: true,
                successTitle: t('sendToFriendSuccess', {
                  currency: currency,
                  amount: bigNumbersPrettier(sendAmount),
                }),
              }),
            );
          } else {
            if (data?.data?.result_text) {
              setErrorMsg(data?.data?.result_text?.trim?.());
            }
          }
          setIsSendLoading(false);
          break;
        default:
          break;
      }
    },
    [currency, dispatch, sendAmount, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className="justify-between flex column full-height flex-1 py-lg"
      style={{minHeight: 440}}>
      <div className="column gap-10">
        <div className="column gap-4">
          <span className="whiteText bold-500 font-12">
            {t('enterFriendPhoneNumber')}
          </span>
          <div className="flex row gap-10 items-end">
            <div
              className="yellowBackground column items-center justify-center rounded-borders"
              style={{width: 47, height: 35}}>
              <span className="blueText font-6 bold-600 resetLineHeight mt-sm text-uppercase">
                {t('phoneCode')}
              </span>
              <span
                className="blueText font-15 bold-600"
                style={{lineHeight: 1.35}}>
                {PHONE_NUMBER_PREFIX}
              </span>
            </div>

            <AppInput
              type={9}
              height={35}
              maxLength={9}
              inputType="number"
              onChange={setFriendPhone}
              setErrorMsg={setErrorMsg}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
              error={
                errorMsg === 'ClientNotVerified'
                  ? t('ClientNotVerified')
                  : friendPhoneErrorMsg || errorMsg
              }
            />
          </div>
        </div>
        <div className="gap-4 full-width column">
          <span className="whiteText bold-500 font-12">{t('amount')}</span>
          <AppInput
            type={9}
            height={35}
            inputType="number"
            onChange={setSendAmount}
            placeholder={t('enterAmountToSend')}
          />
          <span
            className="font-10 whiteText bold-400"
            style={{color: '#FFFFFF59'}}>
            {t('minMaxLimits', {
              currency,
              minValue: 1000,
              maxValue: bigNumbersPrettier(5000000),
            })}
          </span>
        </div>
      </div>
      <div className="flex column gap-7">
        {!user?.is_verified && <AppError error={t('toAllowSend')} />}
        {!user?.is_verified && (
          <AppButton
            type={1}
            height={45}
            className="full-width"
            title={t('verifyNow').toUpperCase()}
            onClick={() => {
              dispatch(
                setMainMenuModal({tabIndex: 2, forceToOpenProfileIndex: 5}),
              );
              // dispatch(
              //   setMainMenuModal({
              //     tab: PROFILE,
              //     isVisible: true,
              //     forceToOpenProfileIndex: 5,
              //   }),
              // );
            }}
          />
        )}
      </div>
      <AppButton
        type={3}
        height={35}
        fontSize={15}
        loading={isSendLoading}
        titleClassName="bold-600"
        disabled={isSendDisabled}
        onClick={sendPressHandler}
        title={t('sendToFriend')?.toUpperCase()}
        className="flex items-center justify-center px-xxl paddingVertical-8"
      />
    </div>
  );
};

export default memo(SendToFriend);
