import React, {memo, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import {useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import './index.scss';

import LobbySkeletons from '../../UI/Skeletons/LobbySkeletons';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SKILL_GAMES_ID,
  CATEGORY_SLOTS_GAMES_ID,
} from '../../../Constants/CasinoMenuFilter';

import {ReactComponent as SlotsIcon} from '../../../Assets/Icons/BottomTabs/slots.svg';
import {ReactComponent as SkillGamesIcon} from '../../../Assets/Icons/BottomTabs/SkillGames.svg';
import {ReactComponent as LiveCasinoIcon} from '../../../Assets/Icons/BottomTabs/LiveCasino.svg';
import {BREAKPOINT_MD} from '../../../Constants/Globals';
import {AppButton} from '../../UI';
import {useSelector} from 'react-redux';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import LobbyGameItem from './LobbyGameItem';

const LobbyGamesContainer = ({setIsGameLoading}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {width} = useScreenSize();

  const {getCasinoGames, goToCasinoGameScreen} = useCasino();

  const user = useSelector(getUser);

  const [skillGames, setSkillGames] = useState([]);
  const [activeGame, setActiveGame] = useState(null);
  const [casinoGames, setCasinoGames] = useState([]);
  const [isGamesLoading, setGamesLoading] = useState(false);
  const [liveCasinoGames, setLiveCasinoGames] = useState([]);

  useEffect(() => {
    setGamesLoading(true);
    const getSlots = getCasinoGames({category: CATEGORY_SLOTS_GAMES_ID});
    const getLiveCasinoGames = getCasinoGames({
      category: CATEGORY_LIVE_CASINO_ID,
    });
    const getSkillGames = getCasinoGames({
      category: CATEGORY_SKILL_GAMES_ID,
    });

    Promise.all([getSlots, getLiveCasinoGames, getSkillGames])
      .then(values => {
        setCasinoGames(values?.[0]?.data?.games);
        setLiveCasinoGames(values?.[1]?.data?.games);
        setSkillGames(values?.[2]?.data?.games);
      })
      ?.catch(err => {
        console.log('ERROR TO GET CATEGORIES AND PROVIDERS ', err);
      })
      ?.finally(() => {
        setTimeout(() => setGamesLoading(false));
      });
  }, []);

  return (
    <div className="lobbyGamesContainer">
      <div>
        <div>
          <div
            style={{backgroundColor: 'var(--darkBlue)', borderRadius: 5}}
            className="px-lg py-sm flex justify-between items-center">
            <div className="flex items-center">
              <SlotsIcon fill="white" width={14} height={14} />
              <span className="whiteText ml-md bold-600">{t('slots')}</span>
            </div>
            <span
              className="font-10 cursor-pointer whiteText bold-600"
              onClick={() => navigate('/casino/slots/')}>
              {t('viewMore')}
            </span>
          </div>
          <div className="flex my-md">
            {isGamesLoading ? (
              <div className="full-width">
                <LobbySkeletons key="casinoGames" />
              </div>
            ) : (
              <Swiper
                freeMode
                keyboard
                mousewheel
                slidesPerView="auto"
                modules={[Keyboard, FreeMode]}
                className="lobbyGamesContainerSwiper full-width">
                {[...casinoGames, 'mock']?.map((item, index) => (
                  <SwiperSlide key={item?.extearnal_game_id ?? 'casinoMock'}>
                    {index === casinoGames?.length ? (
                      <div
                        onClick={() => navigate('/casino/slots/')}
                        className="buttonContainer cursor-pointer full-height blueBackground viewMoreButton rowCenter"
                        style={{
                          width: `${(width - 10 - 4 * 5) / 4}px`,
                        }}>
                        <span className="bold-600 font-20 whiteText text-center">
                          {t('viewMore')}
                        </span>
                      </div>
                    ) : (
                      <LobbyGameItem
                        item={item}
                        activeGame={activeGame}
                        setActiveGame={setActiveGame}
                        setIsGameLoading={setIsGameLoading}
                        clickHandler={goToCasinoGameScreen}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
        <div>
          <div
            style={{backgroundColor: 'var(--darkBlue)', borderRadius: 5}}
            className="px-lg py-sm flex justify-between items-center">
            <div className="flex items-center">
              <LiveCasinoIcon fill="white" width={14} height={14} />
              <span className="whiteText ml-md bold-600">
                {t('liveCasino')}
              </span>
            </div>
            <span
              className="font-10 cursor-pointer whiteText bold-600"
              onClick={() => navigate('/casino/liveCasino')}>
              {t('viewMore')}
            </span>
          </div>
          <div className="flex my-md">
            {isGamesLoading ? (
              <div className="full-width">
                <LobbySkeletons key="liveCasinoGames" />
              </div>
            ) : (
              <Swiper
                freeMode
                keyboard
                mousewheel
                slidesPerView="auto"
                modules={[Keyboard, FreeMode]}
                className="lobbyGamesContainerSwiper full-width">
                {[...liveCasinoGames, 'mock']?.map((item, index) => (
                  <SwiperSlide
                    key={item?.extearnal_game_id ?? 'liveCasinoMock'}>
                    {index === casinoGames?.length ? (
                      <div
                        onClick={() => navigate('/casino/liveCasino/')}
                        className="buttonContainer cursor-pointer full-height blueBackground viewMoreButton rowCenter"
                        style={{
                          width: `${(width - 10 - 4 * 5) / 4}px`,
                        }}>
                        <span className="bold-600 font-20 whiteText text-center">
                          {t('viewMore')}
                        </span>
                      </div>
                    ) : (
                      <LobbyGameItem
                        item={item}
                        activeGame={activeGame}
                        setActiveGame={setActiveGame}
                        setIsGameLoading={setIsGameLoading}
                        clickHandler={goToCasinoGameScreen}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
        <div>
          <div
            style={{backgroundColor: 'var(--darkBlue)', borderRadius: 5}}
            className="px-lg py-sm flex justify-between items-center">
            <div className="flex items-center">
              <SkillGamesIcon fill="white" width={14} height={14} />
              <span className="whiteText ml-md bold-600">
                {t('skillGames')}
              </span>
            </div>
            <span
              className="font-10 cursor-pointer whiteText bold-600"
              onClick={() => navigate('/casino/skill')}>
              {t('viewMore')}
            </span>
          </div>
          <div className="flex my-md">
            {isGamesLoading ? (
              <div className="full-width">
                <LobbySkeletons key="skillGames" />
              </div>
            ) : (
              <Swiper
                freeMode
                keyboard
                mousewheel
                slidesPerView="auto"
                modules={[Keyboard, FreeMode]}
                className="lobbyGamesContainerSwiper full-width">
                {[...skillGames, 'mock']?.map((item, index) => (
                  <SwiperSlide key={item?.extearnal_game_id ?? 'skillMock'}>
                    {index === casinoGames?.length ? (
                      <div
                        onClick={() => navigate('/casino/skill/')}
                        className="buttonContainer cursor-pointer full-height blueBackground viewMoreButton rowCenter"
                        style={{
                          width: `${(width - 10 - 4 * 5) / 4}px`,
                        }}>
                        <span className="bold-600 font-20 whiteText text-center">
                          {t('viewMore')}
                        </span>
                      </div>
                    ) : (
                      <LobbyGameItem
                        item={item}
                        activeGame={activeGame}
                        setActiveGame={setActiveGame}
                        setIsGameLoading={setIsGameLoading}
                        clickHandler={goToCasinoGameScreen}
                      />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LobbyGamesContainer);
