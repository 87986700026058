import React, {memo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {useScreenSize} from '../../Hooks';

import {BREAKPOINT_XS} from '../../Constants/Globals';

import {AppButton} from '../UI';
import BalanceDropDown from '../Home/BalanceDropDown';

import {setMainMenuModal} from '../../Redux/AppSlice';
import {getUser} from '../../Redux/UserSlice/UserSelectors';

import {bigNumbersPrettier} from '../../Utils/BigNumbersPrettier';

import {BALANCE_MANAGEMENT, DEPOSIT} from '../../Constants/MainMenu';

import tzs from '../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as WalletIcon} from '../../Assets/Icons/Globals/wallet.svg';

import './index.scss';

const DepositButton = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const user = useSelector(getUser);

  const balanceDropDownContainerRef = useRef(null);

  const [hideBalance, setHideBalance] = useState(false);
  const [showBalanceDropDown, setShowBalanceDropDown] = useState(false);

  return (
    <div
      className="row items-center gap-10"
      style={{
        left: 0,
        right: 0,
        position: 'absolute',
        justifySelf: 'center',
      }}>
      <div
        className={`depositContainer flex row items-center justify-between gap-5 ${
          width < BREAKPOINT_XS ? 'mobile overflow-hidden ' : 'desktop pa-md'
        }`}>
        <div
          className={`flex row items-center full-width  ${
            width < BREAKPOINT_XS ? 'overflow-hidden' : 'gap-5'
          }`}>
          <img src={tzs} alt="currencyIcon" width={20} height={12} />
          <div
            ref={balanceDropDownContainerRef}
            className={`relative balanceDropDown full-width ${
              width < BREAKPOINT_XS ? 'overflow-hidden' : ''
            }  ${showBalanceDropDown ? 'balanceDropDown_opened' : ''}`}>
            <span
              onClick={() => setHideBalance(prev => !prev)}
              className={`font-1${
                width < BREAKPOINT_XS ? '2' : '2'
              } bold-700 full-width block ${
                showBalanceDropDown ? 'blueText' : 'yellowText'
              } boldText userBalanceText cursor-pointer block py-xs px-sm ${
                width < BREAKPOINT_XS ? 'mobile' : 'desktop'
              }`}>
              {hideBalance ? 'XXX' : bigNumbersPrettier(user?.balance)}
            </span>
            {showBalanceDropDown && (
              <BalanceDropDown
                hideBalance={hideBalance}
                setHideBalance={setHideBalance}
                contentRef={balanceDropDownContainerRef}
                setShowBalanceDropDown={setShowBalanceDropDown}
              />
            )}
          </div>
        </div>
        {/*{width < BREAKPOINT_XS && (*/}
        <div
          className="yellowBackground pa-sm rounded-borders flex items-center justify-center cursor-pointer"
          onClick={() =>
            dispatch(
              setMainMenuModal({
                tabIndex: 0,
                isDesktop: true,
                isVisible: true,
                balanceManagementTabIndex: 0,
              }),
            )
          }>
          <WalletIcon width={13} height={13} fill="var(--appBlue)" />
        </div>
        {/*// )}*/}
      </div>
      {/*{width > BREAKPOINT_XS && (*/}
      {/*  <AppButton*/}
      {/*    type={1}*/}
      {/*    width={80}*/}
      {/*    height={30}*/}
      {/*    fontSize={12}*/}
      {/*    titleClassName="bold-600"*/}
      {/*    title={t('depositUpper')}*/}
      {/*    className="row items-center justify-center"*/}
      {/*    onClick={() =>*/}
      {/*      dispatch(*/}
      {/*        setMainMenuModal({*/}
      {/*          // tab: DEPOSIT,*/}
      {/*          tabIndex: 0,*/}
      {/*          isVisible: true,*/}
      {/*          balanceManagementTabIndex: 0,*/}
      {/*        }),*/}
      {/*      )*/}
      {/*    }*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default memo(DepositButton);
