import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './index.scss';

import {useScreenSize} from '../../../../Hooks';

import {BREAKPOINT_SM} from '../../../../Constants/Globals';

import {formatUnixDate} from '../../../../Utils/DateFormatter';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';

let copyTimeout = null;
let copyTimeout1 = null;

const TransactionHistoryItem = ({item, isColored}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  const [isDocumentIdCopied, setIsDocumentIdCopied] = useState(false);
  const [isTransactionIdCopied, setIsTransactionIdCopied] = useState(false);

  const itemAmountPrefix = useCallback(
    type =>
      type === 'Deposit' ||
      type === 'Correction Up' ||
      type === 'Bet Cashout' ||
      type === 'Increasing the winning' ||
      type === 'Rejected withdrawal request'
        ? '+'
        : '',
    [],
  );

  const copyHandler = useCallback(async (e, text) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(text);
  }, []);

  return (
    <div
      className={`transactionHistoryItem row px-md paddingVertical-8 items-center full-width wrap rounded-borders whiteText font-12 bold-500 ${
        isColored ? 'colored' : 'darkBlueBackground'
      }`}>
      <div className=" col-xxs-3 column justify-between">
        {/*{width < BREAKPOINT_SM && (*/}
        {/*  <span className="bold-700">{t('dateAndId')}</span>*/}
        {/*)}*/}
        <span className="bold-500 font-10 whiteText" style={{opacity: 0.5}}>
          {formatUnixDate(item?.Created)}
        </span>
        <div className="row items-center gap-5">
          <span>
            {t('id')}:{item?.TransactionId}
          </span>
          {isTransactionIdCopied ? (
            <span className="font-9">{t('copied')}</span>
          ) : (
            <CopyIcon
              width={9}
              height={9}
              fill="white"
              onClick={async e => {
                clearTimeout(copyTimeout);

                await copyHandler(e, item?.TransactionId);
                setIsTransactionIdCopied(true);
                copyTimeout = setTimeout(() => {
                  setIsTransactionIdCopied(false);
                }, 1000);
              }}
            />
          )}
        </div>
        <div className="row items-center gap-5">
          <span>
            {t('id')}:{item?.DocumentId}
          </span>
          {isDocumentIdCopied ? (
            <span className="font-9">{t('copied')}</span>
          ) : (
            <CopyIcon
              width={9}
              height={9}
              fill="white"
              onClick={async e => {
                clearTimeout(copyTimeout1);

                await copyHandler(e, item?.DocumentId);
                setIsDocumentIdCopied(true);
                copyTimeout1 = setTimeout(() => {
                  setIsDocumentIdCopied(false);
                }, 1000);
              }}
            />
          )}
        </div>
      </div>
      {/*<div className=" col-xxs-3 row justify-between">*/}
      {/*  {width < BREAKPOINT_SM && <span className="bold-700">{t('date')}</span>}*/}
      {/*  <span>{formatUnixDate(item?.Created)}</span>*/}
      {/*</div>*/}
      <div className=" col-xxs-4 row justify-between bold-500 font-10">
        {/*{width < BREAKPOINT_SM && <span className="bold-700">{t('type')}</span>}*/}
        <span className={`${width < BREAKPOINT_SM ? '' : ''}`}>
          {!!item?.Game ? `${item?.Game} ` : ''}
          {item?.DocumentTypeName}
        </span>
      </div>
      <div
        className={` col-xxs-3  items-center gap-10 row justify-${
          width > BREAKPOINT_SM ? 'start' : 'start'
        } bold-500 font-10 yellowText`}>
        {/*{width < BREAKPOINT_SM && <span className="">{t('amount')}</span>}*/}
        <CurrencyIcon width={21} height={11} />
        <span>
          {itemAmountPrefix(item?.DocumentTypeName)}
          {bigNumbersPrettier(item?.Amount)}
        </span>
      </div>
      <div
        style={{color: '#00BA00'}}
        className={` col-xxs-2 bold-500 items-center gap-10 font-10 row justify-${
          width > BREAKPOINT_SM ? 'start' : 'start'
        }`}>
        <CurrencyIcon width={21} height={11} />

        {/*{width < BREAKPOINT_SM && (*/}
        {/*  <span className="bold-700">{t('finalBalance')}</span>*/}
        {/*)}*/}
        <span>{bigNumbersPrettier(item?.Balance)}</span>
      </div>
    </div>
  );
};

export default memo(TransactionHistoryItem);
