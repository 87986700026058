import {memo, useMemo} from 'react';
import Skeleton from 'react-loading-skeleton';
import {useSelector} from 'react-redux';

import {getShowBetSlip} from '../../../Redux/AppSlice/AppSelectors';
import {getShowCasinoSideBar} from '../../../Redux/CasinoSlice/CasinoSelectors';

import {useScreenSize} from '../../../Hooks';

import {
  BREAKPOINT_MD,
  BREAKPOINT_XS,
  BREAKPOINT_XXS,
} from '../../../Constants/Globals';

const CasinoGamesSkeletons = ({containerRef}) => {
  const {width} = useScreenSize();

  const showBetSlip = useSelector(getShowBetSlip);
  const showCasinoSideBar = useSelector(getShowCasinoSideBar);

  const itemWidth = useMemo(
    () =>
      (containerRef?.current?.offsetWidth || width) /
        (width > BREAKPOINT_MD && !showBetSlip && !showCasinoSideBar
          ? 9
          : width > BREAKPOINT_MD && showBetSlip && showCasinoSideBar
          ? 7
          : width > BREAKPOINT_MD && (showBetSlip || showCasinoSideBar)
          ? 8
          : width < BREAKPOINT_XXS
          ? 4
          : width > BREAKPOINT_XS && width <= 1300
          ? 5
          : 7) -
      6,
    [containerRef, showBetSlip, showCasinoSideBar, width],
  );
  return (
    <div className="flex wrap col-gutter-xs">
      {Array.apply(null, Array(15))?.map((_, eventIndex) => (
        <Skeleton
          count={1}
          duration={0.7}
          key={eventIndex}
          width={itemWidth}
          borderRadius="5px"
          height={itemWidth / 0.675}
          baseColor="var(--lightGray)"
          highlightColor="var(--semiLightGray)"
        />
      ))}
    </div>
  );
};

export default memo(CasinoGamesSkeletons);
