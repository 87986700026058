import React, {memo, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {getTaxPercentage} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import CalculationRow from '../CalculationRow';

import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as Chevron} from '../../../../Assets/Icons/Globals/chevronBottom.svg';

const CashOutCalculations = ({
  tax,
  stake,
  betId,
  bonus,
  payout,
  outcome,
  totalOdds,
  finalPayout,
}) => {
  const {t} = useTranslation();

  const taxPercentage = useSelector(getTaxPercentage);

  const [showRestCalculations, setShowRestCalculations] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded: showRestCalculations,
  });

  return (
    <div
      className="column gap-10 cashOutCalculationsContainer px-sm py-md"
      style={{borderBottom: '1px solid white'}}>
      <div className="column">
        <CalculationRow
          amountTextClassName={`whiteText bold-600 font-15`}
          hideCurrency
          amountContainerHeight={20}
          amountContainerWidth="100%"
          amount={totalOdds}
          title={
            <span className={`bold-600 font-16 flex-shrink whiteText`}>
              {t('totalOdds')}
            </span>
          }
          amountContainerClassName="pr-none"
          titleClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
          amountClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
        />
        <div {...getCollapseProps()}>
          <CalculationRow
            amountContainerClassName="pr-none"
            amountTextClassName={`whiteText bold-600 font-15`}
            hideCurrency
            amountContainerHeight={20}
            amountContainerWidth="100%"
            amount={stake}
            title={
              <span className={`bold-600 font-16 flex-shrink whiteText`}>
                {t('stake')}
              </span>
            }
            titleClassName={
              outcome
                ? 'whiteText bold-700 font-12'
                : 'whiteText bold-700 font-12'
            }
            amountClassName={
              outcome
                ? 'whiteText bold-700 font-12'
                : 'whiteText bold-700 font-12'
            }
          />
          {!!tax && (
            <CalculationRow
              amountTextClassName={`whiteText bold-600 font-15`}
              hideCurrency
              amountContainerClassName="pr-none"
              amountContainerHeight={20}
              amountContainerWidth="100%"
              title={
                <span className="whiteText bold-600 font-16 flex-shrink">
                  <span className="">{t('tax')}</span>
                  <span> ({taxPercentage}%)</span>
                </span>
              }
              amount={tax}
              amountClassName={
                outcome
                  ? 'whiteText bold-700 font-12'
                  : 'whiteText bold-700 font-12'
              }
            />
          )}
          {!!bonus && (
            <CalculationRow
              amountTextClassName={`whiteText bold-600 font-15`}
              hideCurrency
              amountContainerClassName="pr-none"
              amountContainerHeight={20}
              amountContainerWidth="100%"
              amount={bonus}
              amountClassName="whiteText"
              title={
                <span className={`bold-600 font-16 flex-shrink`}>
                  {t('accumulatorBonus')}
                </span>
              }
            />
          )}
        </div>
        {!!finalPayout && !outcome && (
          <CalculationRow
            amountTextClassName={`whiteText bold-600 font-15`}
            amountContainerHeight={20}
            amountContainerWidth="100%"
            amountContainerClassName="pr-none"
            title={
              <div className="row items-center gap-10 flex-shrink whiteText">
                <span className={`bold-600 font-16 flex-shrink`}>
                  {t('finalPayout')}
                </span>
                <div
                  {...getToggleProps({
                    onClick: () => setShowRestCalculations(prev => !prev),
                  })}>
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      flexShrink: 0,
                      borderRadius: '50%',
                      backgroundColor: showRestCalculations
                        ? 'var(--appYellow)'
                        : 'transparent',
                      outline: `1px solid ${
                        showRestCalculations ? 'transparent' : 'white'
                      }`,
                    }}
                    className="row items-center justify-center">
                    <Chevron
                      fill={showRestCalculations ? 'var(--appBlue)' : 'white'}
                      style={
                        showRestCalculations
                          ? {}
                          : {transform: 'rotate(180deg)'}
                      }
                    />
                  </div>
                </div>
              </div>
            }
            hideCurrency
            amount={
              <div className="row items-center gap-10 overflow-hidden">
                <CurrencyIcon width={18} height={10} className="flex-shrink" />
                <span className={`whiteText font-18 bold-700 ellipsis`}>
                  {fixedNumberWithoutRound(finalPayout)}
                </span>
              </div>
            }
            amountClassName="whiteText"
          />
        )}
        {!!payout && outcome !== 1 && (
          <CalculationRow
            amountTextClassName={`whiteText bold-600 font-15`}
            hideCurrency
            amountContainerClassName="pr-none"
            amountContainerHeight={20}
            amountContainerWidth="100%"
            amount={payout}
            amountClassName="whiteText"
            title={
              <span className="whiteText bold-600 font-16 flex-shrink">
                {outcome === 5
                  ? t('cashedOut')
                  : outcome === 3
                  ? t('wonAmount')
                  : t('returned')}
              </span>
            }
          />
        )}
      </div>
    </div>
  );
};

export default memo(CashOutCalculations);
