import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';

import {useSocket} from '../../../../Hooks';

import {GET_MAX_BET} from '../../../../Constants/Socket';
import {SINGLE_BET} from '../../../../Constants/Betting';

import CalculationsBlock from '../CalculationsBlock';

import {AppButton, AppInput} from '../../../UI';

const SingleBetCalculationsBlock = ({
  eventId,
  marketId,
  eventOdd,
  eventsCount,
  setEventsData,
  allEventsData,
}) => {
  const {t} = useTranslation();
  const {getMaxBet} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const inputRef = useRef(null);

  const [stakeAmount, setStakeAmount] = useState(
    allEventsData?.find(item => item?.id === eventId)?.amount || '',
  );
  const [isMaxLoading, setIsMaxLoading] = useState(false);

  const getMax = useCallback(() => {
    setIsMaxLoading(true);
    getMaxBet({
      rid: `${GET_MAX_BET}_${eventId}`,
      type: 1, // Here is SINGLE BET CASE
      eventsArray: [eventId],
    });
  }, [eventId, getMaxBet]);

  const setInputValue = useCallback(
    value => {
      setStakeAmount(value);
      setEventsData(prev => {
        const prevState = [...(prev || [])];
        const foundGame = prevState?.find(game => game?.unique_id === eventId);

        if (foundGame) {
          prevState.find(game => game.unique_id === eventId).market[
            marketId
          ].event[eventId] = {
            ...foundGame.market[marketId].event[eventId],
            amount: value,
          };
        }
        return prevState;
      });
    },
    [eventId, marketId, setEventsData],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case `${GET_MAX_BET}_${eventId}`:
          if (data?.data?.result === 0) {
            setInputValue(data?.data?.details?.amount?.toString?.());
          }
          setIsMaxLoading(false);
          break;
        default:
          break;
      }
    },
    [eventId, setInputValue],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="pt-md">
      <div className="row items-center justify-between gap-5">
        <AppInput
          type={1}
          inputType="number"
          inputRef={inputRef}
          inputValue={stakeAmount}
          onChange={setInputValue}
          className="bold-700 text-caption"
          placeholder={t('amountPlaceholder')}
        />

        {isLoggedIn && (
          <AppButton
            type={12}
            height={45}
            title={t('max')}
            onClick={getMax}
            className="pa-md"
            width="fit-content"
            loading={isMaxLoading}
          />
        )}
      </div>
      <div className="pt-md">
        {stakeAmount?.trim?.() && (
          <CalculationsBlock
            odds={eventOdd}
            betType={SINGLE_BET}
            eventsCount={eventsCount}
            stakeAmount={stakeAmount}
          />
        )}
      </div>
    </div>
  );
};

export default memo(SingleBetCalculationsBlock);
