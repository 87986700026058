import {useCallback} from 'react';
import md5 from 'md5';
import {useDispatch, useSelector} from 'react-redux';

// import store from '../Redux/store';
import {
  getSid,
  getLanguage,
  getWssSocket,
} from '../Redux/AppSlice/AppSelectors';
// import {
//   setSubIds,
//   setIsGameDataLoading,
//   setIsSportDataLoading,
//   setMultipleGamesSubIds,
// } from '../Redux/SportSlice';

import {
  // GET,
  // USER,
  // DO_BET,
  // BETTING,
  SITE_ID,
  // DEPOSIT,
  // CASH_OUT,
  // GET_USER,
  // WITHDRAW,
  // GET_GAMES,
  // USER_LIMIT,
  // LOGIN_USER,
  // MOBILE_KEY,
  // LOGOUT_USER,
  // UPDATE_USER,
  // GET_MAX_BET,
  // MAKE_DEPOSIT,
  // USER_MESSAGES,
  // RESTORE_LOGIN,
  // REGISTER_USER,
  // NOTIFICATIONS,
  // SET_USER_LIMIT,
  // GET_ALL_SPORTS,
  REMOVE_SESSION,
  REQUEST_SESSION,
  // RESET_PASSWORD,
  // GET_WITHDRAWALS,
  // CANCEL_WITHDRAW,
  GET_All_LEAGUES,
  // GET_MATCH_SCORES,
  // APPLY_PROMO_CODE,
  // GET_COMPETITIONS,
  // GET_BETS_HISTORY,
  // UNSUBSCRIBE_BULK,
  // GET_INITIAL_GAMES,
  // READ_USER_MESSAGE,
  // GET_BOOSTED_GAMES,
  // GET_SPECIFIC_GAME,
  // VALIDATE_RECAPTCHA,
  // GET_BOOSTED_SPORTS,
  // GET_PARTNER_CONFIG,
  // DELETE_USER_MESSAGE,
  // SUBSCRIBE_TO_EVENTS,
  // GET_PAYMENT_SERVICES,
  // GET_PARTNER_BIG_WINS,
  UPDATE_USER_PASSWORD,
  // GET_PARTNER_LAST_WINS,
  // GET_SPORT_BONUS_RULES,
  // GET_OPEN_BETS_HISTORY,
  // GET_OPENED_BETS_COUNT,
  // UNSUBSCRIBE_LIVE_EVENT,
  // SEND_SMS_WITH_USERNAME,
  // GET_BOOSTED_SELECTIONS,
  // RESET_PASSWORD_VIA_SMS,
  // GET_TRANSACTION_HISTORY,
  CHANGE_SESSION_LANGUAGE,
  UNSUBSCRIBE_LIVE_EVENT,
  GET,
  GET_All_REGIONS,
  BETTING,
  LOGIN_USER,
  LOGOUT_USER,
  RESTORE_LOGIN,
  LOGIN_ENCRYPTED,
  REGISTER_USER,
  GET_USER,
  USER,
  BOOK_BET,
  VALIDATE_REGISTER_RECAPTCHA,
  VALIDATE_RECAPTCHA,
  MOBILE_KEY,
  GET_PARTNER_CONFIG,
  GET_COMPETITIONS,
  GET_GAMES,
  GET_INITIAL_GAMES,
  GET_ALL_SPORTS,
  UNSUBSCRIBE_BULK,
  UPDATE_USER,
  SEND_SMS_WITH_USERNAME,
  SET_CLIENT_SELF_EXCLUSION,
  DEPOSIT,
  SET_USER_LIMIT,
  USER_LIMIT,
  USER_MESSAGES,
  READ_USER_MESSAGE,
  DELETE_USER_MESSAGE,
  USER_MESSAGES_SENT,
  USER_MESSAGES_INCOMING,
  ADD_USER_MESSAGE,
  RESET_PASSWORD_VIA_SMS,
  GET_TRANSACTION_HISTORY,
  GET_PARTNER_DOCUMENT_TYPES,
  GET_SPECIFIC_GAME,
  GET_PAYMENT_SERVICES,
  MAKE_DEPOSIT,
  WITHDRAW,
  GET_PARTNER_LAST_WINS,
  GET_PARTNER_BIG_WINS,
  SUBSCRIBE_TO_EVENTS,
  GET_SPORT_BONUS_RULES,
  GET_MAX_BET,
  DO_BET,
  GET_OPENED_BETS_COUNT,
  GET_BETS_HISTORY,
  SUBSCRIBE_TO_CASH_OUT_AMOUNT,
  NOTIFICATIONS,
  GET_OPEN_BETS_HISTORY,
  GET_MATCH_SCORES,
  CASH_OUT,
  GET_BOOSTED_SELECTIONS,
  GET_BOOSTED_GAMES,
  GET_BOOSTED_SPORTS,
  GET_ALL_POPULAR_COMPETITIONS,
  GET_SEARCHED_GAMES,
  GET_FEATURED_GAMES,
  GET_BOOKED_BETS,
  BUDDY_TO_BUDDY_TRANSFER,
  GET_IMAGES,
  UPLOAD_IMAGE,
  GET_FIXED_COMPETITIONS,
  CHECK_BET_STATUS,
  GET_USER_DATA_PERSONALIZATION,
  SET_USER_DATA_PERSONALIZATION,
  DELETE_USER_DATA_PERSONALIZATION,
  GET_FREE_BETS_FOR_BETSLIP,
  GET_FAVOURITE_COMPETITIONS,
  // GET_PREDEFINED_MULTIPLES,
  // SET_CLIENT_SELF_EXCLUSION,
  // GET_PARTNER_DOCUMENT_TYPES,
  // VALIDATE_REGISTER_RECAPTCHA,
  // SUBSCRIBE_TO_CASH_OUT_AMOUNT,
} from '../Constants/Socket';
import {
  FOOTBALL_ALIAS,
  LIMIT_OF_ALL_GAMES,
  OTHER_SPORTS_MARKETS,
  FILTERS_POPULAR_COMPETITIONS_STATUS,
  FILTERS_FEATURED_MATCHES_STATUS,
  MARKETS_NAMES,
} from '../Constants/MatchFilters';
import {
  setSubIds,
  setIsGameDataLoading,
  setIsSportDataLoading,
} from '../Redux/SportSlice';
import store from '../Redux/store';
import {DEFAULT_TRANSACTION_TYPE} from '../Constants/TransactionHistoryFilters';

const useSocket = () => {
  const dispatch = useDispatch();

  const sid = useSelector(getSid);
  const language = useSelector(getLanguage);
  const wssSocket = useSelector(getWssSocket);

  const socketQuery = useCallback(
    ({command, params, rid}) => {
      if (wssSocket?.readyState === WebSocket.OPEN) {
        wssSocket.send(JSON.stringify({command, params, rid}));
      }
    },
    [wssSocket, sid],
  );

  const requestSession = useCallback(
    lang => {
      socketQuery({
        command: REQUEST_SESSION,
        params: {
          language: lang || language,
          site_id: SITE_ID,
        },
        rid: REQUEST_SESSION,
      });
    },
    [language, socketQuery],
  );

  const removeSession = useCallback(() => {
    socketQuery({
      command: REMOVE_SESSION,
      rid: REMOVE_SESSION,
    });
  }, [socketQuery]);

  const changeSessionLanguage = useCallback(
    lang => {
      socketQuery({
        command: CHANGE_SESSION_LANGUAGE,
        params: {
          language: lang || language,
        },
        rid: CHANGE_SESSION_LANGUAGE,
      });
    },
    [language, socketQuery],
  );

  const unSubscribeLiveEvents = useCallback(
    ({rid = UNSUBSCRIBE_LIVE_EVENT, subId}) =>
      socketQuery({
        rid,
        command: UNSUBSCRIBE_LIVE_EVENT,
        params: {
          subid: subId,
        },
      }),
    [socketQuery],
  );

  const unSubscribeBulk = useCallback(
    ({rid = UNSUBSCRIBE_BULK, subIdsArray}) =>
      socketQuery({
        rid,
        command: UNSUBSCRIBE_BULK,
        params: {
          subids: subIdsArray,
        },
      }),
    [socketQuery],
  );

  const unSubscribeAllEvents = useCallback(() => {
    const {
      games: gamesSubId,
      sports: sportsSubId,
      boostedGames: boostedGamesSubId,
    } = store.getState()?.sport?.subIds;

    // const multipleSubIdsArray = store.getState()?.sport?.multipleGamesSubIds;

    if (
      !!gamesSubId ||
      !!sportsSubId ||
      !!boostedGamesSubId
      // || multipleSubIdsArray?.length
    ) {
      dispatch(setSubIds({sports: '', games: '', boostedGames: ''}));
      // dispatch(setMultipleGamesSubIds([]));
      unSubscribeBulk({
        subIdsArray: [
          // ...multipleSubIdsArray,
          ...(gamesSubId ? [gamesSubId] : []),
          ...(sportsSubId ? [sportsSubId] : []),
          ...(boostedGamesSubId ? [boostedGamesSubId] : []),
        ],
      });
    }
  }, [dispatch, unSubscribeBulk]);
  //
  // const getMultiples = useCallback(() => {
  //   dispatch(setIsGameDataLoading(true));
  //   unSubscribeAllEvents();
  //
  //   socketQuery({
  //     rid: GET_PREDEFINED_MULTIPLES,
  //     command: GET_PREDEFINED_MULTIPLES,
  //     params: {},
  //   });
  // }, [dispatch, socketQuery, unSubscribeAllEvents]);
  //
  const getBoostedSelections = useCallback(() => {
    // dispatch(setIsSportDataLoading(true));
    // dispatch(setIsGameDataLoading(true));
    // unSubscribeAllEvents();

    socketQuery({
      rid: GET_BOOSTED_SELECTIONS,
      command: GET_BOOSTED_SELECTIONS,
      params: {},
    });
  }, [socketQuery]);

  const buddyToBuddyTransfer = useCallback(
    ({amount, to_user}) =>
      socketQuery({
        rid: BUDDY_TO_BUDDY_TRANSFER,
        command: BUDDY_TO_BUDDY_TRANSFER,
        params: {
          amount,
          to_user,
        },
      }),
    [socketQuery],
  );

  const getImages = useCallback(
    () =>
      socketQuery({
        rid: GET_IMAGES,
        command: GET_IMAGES,
        params: {},
      }),
    [socketQuery],
  );

  const uploadImage = useCallback(
    ({image_data, name, image_type}) =>
      socketQuery({
        rid: UPLOAD_IMAGE,
        command: UPLOAD_IMAGE,
        params: {
          name,
          image_data,
          image_type,
        },
      }),
    [socketQuery],
  );

  const getSports = useCallback(
    ({gameFilters, rid = GET_ALL_SPORTS}) => {
      dispatch(setIsSportDataLoading(true));
      dispatch(setIsGameDataLoading(true));

      unSubscribeAllEvents();
      socketQuery({
        rid,
        command: GET,
        params: {
          source: BETTING,
          what: {
            game: '@count',
            sport: ['id', 'alias', 'name', 'order'],
          },
          where: {
            ...gameFilters,
            sport: {type: {'@nin': [1, 4]}},
          },
          subscribe: true,
        },
      });
    },
    [dispatch, socketQuery, unSubscribeAllEvents],
  );

  const getBoostedSports = useCallback(
    ({gameIdsArray}) => {
      dispatch(setIsSportDataLoading(true));
      dispatch(setIsGameDataLoading(true));
      unSubscribeAllEvents();

      socketQuery({
        command: GET,
        rid: GET_BOOSTED_SPORTS,
        params: {
          source: BETTING,
          what: {
            game: '@count',
            sport: ['id', 'alias', 'name', 'order'],
          },
          where: {
            game: {id: {'@in': gameIdsArray}},
          },
          subscribe: true,
        },
      });
    },
    [dispatch, socketQuery, unSubscribeAllEvents],
  );

  const getInitialGames = useCallback(
    ({
      search,
      gameFilters,
      limit = null,
      activeSportAlias,
      showLoader = true,
      withoutLimit = false,
      competitionsIdsArray = null,
    }) => {
      dispatch(setIsGameDataLoading(showLoader));
      const gamesSubId = store.getState()?.sport?.subIds?.games;
      if (gamesSubId) {
        unSubscribeLiveEvents({subId: gamesSubId});
      }

      socketQuery({
        command: GET,
        rid: `${GET_INITIAL_GAMES}${
          search ? `_search=${search}` : ''
        }`?.trim?.(),
        params: {
          source: BETTING,
          what: {
            sport: ['id', 'alias'],
            region: ['id', 'alias', 'order'],
            competition: ['id', 'order', 'name', 'favorite_order'],
            event: ['base', 'price', 'name', 'order', 'id'],
            market: [
              'id',
              'type',
              'base',
              'event',
              'cashout',
              'col_count',
              'market_type',
              'display_key',
              'display_sub_key',
            ],
            game: [
              [
                'id',
                'info',
                'stats',
                'is_live',
                'start_ts',
                'team2_id',
                'team1_id',
                'show_type',
                'text_info',
                'show_type',
                'is_blocked',
                'team1_name',
                'team2_name',
                'sport_alias',
                'region_alias',
                'markets_count',
                'competition_name',
              ],
            ],
          },
          where: {
            game: {
              ...gameFilters?.game,
              ...(gameFilters?.game?.promoted === true || withoutLimit
                ? {}
                : !!limit
                ? {'@limit': limit}
                : {'@limit': LIMIT_OF_ALL_GAMES}),
              ...(search
                ? {
                    '@or': [
                      {team1_name: {'@like': {eng: search}}},
                      {team2_name: {'@like': {eng: search}}},
                    ],
                  }
                : {}),
            },
            sport: {alias: activeSportAlias},
            competition: {
              ...(competitionsIdsArray
                ? {
                    id: {
                      '@in': competitionsIdsArray ? competitionsIdsArray : [],
                    },
                  }
                : {}),
            },
            market:
              MARKETS_NAMES?.[activeSportAlias] ||
              MARKETS_NAMES.OTHER_SPORTS_MARKETS,
          },
          subscribe: true,
        },
      });
    },
    [dispatch, socketQuery, unSubscribeLiveEvents],
  );

  const getSearchedGames = useCallback(
    ({search}) => {
      socketQuery({
        command: GET,
        rid: `${GET_SEARCHED_GAMES}${
          search ? `_search=${search}` : ''
        }`?.trim?.(),
        params: {
          source: BETTING,
          what: {
            sport: ['alias'],
            region: ['alias'],
            competition: ['favorite', 'id'],
            game: [
              'id',
              'type',
              'is_live',
              'start_ts',
              'team2_id',
              'team1_id',
              'promoted',
              'show_type',
              'team1_name',
              'team2_name',
              'visible_in_prematch',
            ],
          },
          where: {
            game: {
              ...(search
                ? {
                    '@or': [
                      {team1_name: {'@like': {eng: search}}},
                      {team2_name: {'@like': {eng: search}}},
                    ],
                  }
                : {}),
            },
          },
          subscribe: false,
        },
      });
    },
    [socketQuery],
  );

  const getGames = useCallback(
    ({
      regionAlias,
      gameFilters,
      competitionId,
      activeSportAlias,
      showLoader = true,
    }) => {
      dispatch(setIsGameDataLoading(showLoader));
      const gamesSubId = store.getState()?.sport?.subIds?.games;
      if (gamesSubId) {
        unSubscribeLiveEvents({subId: gamesSubId});
      }

      socketQuery({
        command: GET,
        rid: GET_GAMES,
        params: {
          source: BETTING,
          what: {
            competition: ['id', 'name'],
            sport: ['id', 'alias'],
            region: ['id', 'alias'],
            event: ['base', 'price', 'name', 'order', 'id'],
            market: [
              'id',
              'type',
              'base',
              'event',
              'cashout',
              'col_count',
              'market_type',
              'display_key',
              'display_sub_key',
            ],
            game: [
              [
                'id',
                'info',
                'stats',
                'is_live',
                'start_ts',
                'team2_id',
                'team1_id',
                'show_type',
                'text_info',
                'is_blocked',
                'team1_name',
                'team2_name',
                'sport_alias',
                'region_alias',
                'markets_count',
                'competition_name',
              ],
            ],
          },
          where: {
            ...(regionAlias ? {region: {alias: regionAlias}} : {}),
            sport: {alias: activeSportAlias},
            competition: {
              id: {'@in': competitionId},
              ...(gameFilters?.competition || {}),
            },
            market:
              MARKETS_NAMES?.[activeSportAlias] ||
              MARKETS_NAMES?.['OTHER_SPORTS_MARKETS'],
            game: {...(gameFilters?.game || {})},
          },
          subscribe: true,
        },
      });
    },
    [dispatch, socketQuery, unSubscribeLiveEvents],
  );

  const getFeaturedGames = useCallback(() => {
    socketQuery({
      command: GET,
      rid: GET_FEATURED_GAMES,
      params: {
        source: BETTING,
        what: {
          sport: ['id', 'alias'],
          region: ['id', 'alias', 'order'],
          competition: ['id', 'order', 'name'],
          event: ['name', 'price', 'order', 'id', 'base'],
          market: [
            'id',
            'type',
            'base',
            'event',
            'cashout',
            'col_count',
            'market_type',
            'display_key',
            'display_sub_key',
          ],
          game: [
            [
              'id',
              'info',
              'stats',
              'is_live',
              'team2_id',
              'start_ts',
              'team1_id',
              'show_type',
              'text_info',
              'is_blocked',
              'team1_name',
              'team2_name',
              'sport_alias',
              'region_alias',
              'markets_count',
              'competition_name',
            ],
          ],
        },
        subscribe: true,
        where: {...FILTERS_FEATURED_MATCHES_STATUS},
      },
    });
  }, [socketQuery]);

  // const subscribeToSpecificEvents = useCallback(
  //   ({gameIdsArray, marketIdsArray, eventIdsArray}) =>
  //     socketQuery({
  //       command: GET,
  //       rid: SUBSCRIBE_TO_EVENTS,
  //       params: {
  //         source: BETTING,
  //         what: {
  //           event: ['id', 'price', 'name', 'type_1', 'base'],
  //           market: [
  //             'id',
  //             'name',
  //             'cashout',
  //             'group_name',
  //             'express_id',
  //             'market_type',
  //           ],
  //           game: [
  //             'id',
  //             'info',
  //             'is_live',
  //             'start_ts',
  //             'text_info',
  //             'is_blocked',
  //             'team1_name',
  //             'team2_name',
  //             'sport_alias',
  //           ],
  //         },
  //         where: {
  //           game: {id: gameIdsArray},
  //           event: {id: eventIdsArray},
  //           market: {id: marketIdsArray},
  //         },
  //         subscribe: true,
  //       },
  //     }),
  //   [socketQuery],
  // );

  const subscribeToEvent = useCallback(
    ({rid = SUBSCRIBE_TO_EVENTS, gameId, eventId, marketId}) =>
      socketQuery({
        rid,
        command: GET,
        params: {
          source: BETTING,
          what: {
            event: ['id', 'price', 'name', 'type_1', 'base'],
            market: [
              'id',
              'name',
              'cashout',
              'group_name',
              'express_id',
              'market_type',
              'prematch_express_id',
            ],
            game: [
              'id',
              'info',
              'stats',
              'is_live',
              'team2_id',
              'team1_id',
              'start_ts',
              'show_type',
              'text_info',
              'is_blocked',
              'team1_name',
              'team2_name',
              'sport_alias',
            ],
          },
          where: {
            game: {id: gameId},
            event: {id: eventId},
            market: {id: marketId},
          },
          subscribe: true,
        },
      }),
    [socketQuery],
  );

  // const getMultipleGames = useCallback(
  //   ({eventIdsArray, gameIdsArray, marketIdsArray, rid}) => {
  //     socketQuery({
  //       rid,
  //       command: GET,
  //       params: {
  //         source: BETTING,
  //         what: {
  //           market: ['name', 'id'],
  //           event: ['name', 'price', 'base', 'id'],
  //           game: [
  //             'id',
  //             'start_ts',
  //             'is_blocked',
  //             'team1_name',
  //             'sport_alias',
  //             'team2_name',
  //             '_parent_id',
  //             'region_alias',
  //             'markets_count',
  //           ],
  //         },
  //         where: {
  //           game: {id: {'@in': gameIdsArray}},
  //           event: {id: {'@in': eventIdsArray}},
  //           market: {id: {'@in': marketIdsArray}},
  //         },
  //         subscribe: true,
  //       },
  //     });
  //   },
  //   [socketQuery],
  // );
  //
  const getBoostedGames = useCallback(
    ({gameIdsArray, activeSportAlias}) => {
      dispatch(setIsGameDataLoading(true));
      socketQuery({
        rid: GET_BOOSTED_GAMES,
        command: GET,
        params: {
          source: BETTING,
          what: {
            sport: ['id', 'alias'],
            competition: ['name', 'id'],
            region: ['alias', 'order', 'id'],
            event: ['base', 'price', 'name', 'order', 'id'],
            market: [
              'id',
              'type',
              'base',
              'event',
              'cashout',
              'col_count',
              'market_type',
              'display_key',
              'display_sub_key',
            ],
            game: [
              [
                'id',
                'info',
                'stats',
                'is_live',
                'start_ts',
                'team2_id',
                'team1_id',
                'show_type',
                'text_info',
                'is_blocked',
                'team1_name',
                'team2_name',
                'sport_alias',
                'region_alias',
                'markets_count',
                'competition_name',
              ],
            ],
          },
          where: {
            game: {id: {'@in': gameIdsArray}},
            market:
              MARKETS_NAMES?.[activeSportAlias] ||
              MARKETS_NAMES.OTHER_SPORTS_MARKETS,
          },
          subscribe: true,
        },
      });
    },
    [dispatch, socketQuery],
  );

  const getSpecificGame = useCallback(
    ({gameId}) => {
      socketQuery({
        rid: GET_SPECIFIC_GAME,
        command: GET,
        params: {
          source: BETTING,
          what: {
            sport: ['name', 'id'],
            event: ['id', 'base', 'name', 'price', 'order'],
            market: [
              'id',
              'name',
              'type',
              'order',
              'cashout',
              'group_id',
              'team2_id',
              'team1_id',
              'col_count',
              'group_name',
              'group_order',
              'market_type',
              'display_key',
            ],
            competition: ['name', 'id'],
            region: ['alias', 'name', 'id'],
            game: [
              // [
              //   'id',
              //   'info',
              //   'stats',
              //   'is_live',
              //   'team1_id',
              //   'team2_id',
              //   'start_ts',
              //   'text_info',
              //   'team2_name',
              //   'team1_name',
              //   'is_blocked',
              //   'sport_alias',
              // ],
            ],
          },
          where: {
            game: {id: gameId},
          },
          subscribe: true,
        },
      });
    },
    [socketQuery],
  );

  const getSpecificGameStats = useCallback(
    ({rid, gameId}) => {
      socketQuery({
        rid,
        command: GET,
        params: {
          source: BETTING,
          what: {
            game: [],
          },
          where: {
            game: {id: gameId},
          },
          subscribe: true,
        },
      });
    },
    [socketQuery],
  );

  const getCompetitions = useCallback(
    ({activeSportAlias, regionAlias, gameFilters}) =>
      socketQuery({
        command: GET,
        rid: GET_COMPETITIONS,
        params: {
          source: BETTING,
          what: {
            game: '@count',
            competition: ['name', 'id', 'order'],
          },
          where: {
            region: {alias: regionAlias},
            sport: {alias: activeSportAlias},
            ...gameFilters,
          },
          subscribe: false,
        },
      }),
    [socketQuery],
  );

  const getFixedCompetitions = useCallback(
    ({activeSportAlias, competitionsIdsArray, gameFilters}) => {
      socketQuery({
        command: GET,
        rid: GET_FIXED_COMPETITIONS,
        params: {
          source: BETTING,
          what: {
            region: ['id', 'alias'],
            competition: ['name', 'id', 'order'],
            game: ['start_ts', 'show_type'],
          },
          where: {
            sport: {alias: activeSportAlias},
            competition: {id: {'@in': competitionsIdsArray}},
            ...gameFilters,
          },
          subscribe: false,
        },
      });
    },
    [socketQuery],
  );

  const getFavouriteCompetitionGames = useCallback(
    ({activeSportAlias, competitionsIdsArray, gameFilters}) => {
      socketQuery({
        command: GET,
        rid: GET_FAVOURITE_COMPETITIONS,
        params: {
          source: BETTING,
          what: {
            sport: ['id', 'alias'],
            region: ['id', 'alias', 'order'],
            competition: ['id', 'order', 'name', 'favorite_order'],
            event: ['base', 'price', 'name', 'order', 'id'],
            market: [
              'id',
              'type',
              'base',
              'event',
              'cashout',
              'col_count',
              'market_type',
              'display_key',
              'display_sub_key',
            ],
            game: [
              [
                'id',
                'info',
                'stats',
                'is_live',
                'start_ts',
                'team2_id',
                'team1_id',
                'show_type',
                'text_info',
                'show_type',
                'is_blocked',
                'team1_name',
                'team2_name',
                'sport_alias',
                'region_alias',
                'markets_count',
                'competition_name',
              ],
            ],
          },
          where: {
            sport: {alias: activeSportAlias},
            competition: {id: {'@in': competitionsIdsArray}},
          },
          subscribe: false,
        },
      });
    },
    [socketQuery],
  );

  const getLeagues = useCallback(
    ({activeSportAlias, gameFilters}) =>
      socketQuery({
        command: GET,
        rid: GET_All_REGIONS,
        params: {
          source: BETTING,
          what: {
            region: ['alias', 'id', 'name', 'order'],
          },
          where: {
            sport: {alias: activeSportAlias},
            ...gameFilters,
          },
          subscribe: false,
        },
      }),
    [socketQuery],
  );

  const getPopularCompetitions = useCallback(
    ({activeSportAlias}) =>
      socketQuery({
        command: GET,
        rid: GET_ALL_POPULAR_COMPETITIONS,
        params: {
          source: BETTING,
          what: {
            region: ['alias'],
            competition: ['id', 'name', 'favorite_order', 'order'],
          },
          where: {
            sport: {alias: activeSportAlias},
            ...FILTERS_POPULAR_COMPETITIONS_STATUS,
          },
          subscribe: true,
        },
      }),
    [socketQuery],
  );

  const getFavouriteCompetition = useCallback(
    () =>
      socketQuery({
        command: GET_USER_DATA_PERSONALIZATION,
        rid: GET_USER_DATA_PERSONALIZATION,
        params: {
          scope: 'cms_pro',
          resource: ['sportFavoriteCompetition'],
        },
      }),
    [socketQuery],
  );

  const setFavouriteCompetition = useCallback(
    ({idsObject}) =>
      socketQuery({
        rid: SET_USER_DATA_PERSONALIZATION,
        command: SET_USER_DATA_PERSONALIZATION,
        params: {
          scope: 'cms_pro',
          resource: 'sportFavoriteCompetition',
          configs: idsObject,
        },
      }),
    [socketQuery],
  );

  const deleteFavoriteCompetition = useCallback(
    ({competitionId}) =>
      socketQuery({
        rid: DELETE_USER_DATA_PERSONALIZATION,
        command: DELETE_USER_DATA_PERSONALIZATION,
        params: {
          _id: competitionId,
        },
      }),
    [socketQuery],
  );

  const getInboxes = useCallback(
    ({type, rid = USER_MESSAGES_INCOMING}) =>
      socketQuery({
        rid,
        command: USER_MESSAGES,
        params: {
          where: {type},
        },
      }),
    [socketQuery],
  );

  const deleteInbox = useCallback(
    ({inboxId, type = 'inbox'}) =>
      socketQuery({
        command: DELETE_USER_MESSAGE,
        rid: `${DELETE_USER_MESSAGE}-${inboxId}-${type}`,
        params: {
          message_id: inboxId,
        },
      }),
    [socketQuery],
  );

  const readInbox = useCallback(
    ({inboxId, index}) =>
      socketQuery({
        command: READ_USER_MESSAGE,
        rid: `${READ_USER_MESSAGE}-${index}`,
        params: {
          message_id: inboxId,
        },
      }),
    [socketQuery],
  );

  const addUserMessage = useCallback(
    ({subject = '', body = ''}) =>
      socketQuery({
        rid: ADD_USER_MESSAGE,
        command: ADD_USER_MESSAGE,
        params: {
          body,
          subject,
        },
      }),
    [socketQuery],
  );

  const login = useCallback(
    ({username, password, rid = LOGIN_USER, encrypted_token = false}) =>
      socketQuery({
        rid,
        command: LOGIN_USER,
        params: {
          username,
          password,
          encrypted_token,
        },
      }),
    [socketQuery],
  );

  const logout = useCallback(
    ({jwe_token}) =>
      socketQuery({
        rid: LOGOUT_USER,
        command: LOGOUT_USER,
        params: {
          ...(jwe_token ? {jwe_token} : {}),
        },
      }),
    [socketQuery],
  );

  const restoreLogin = useCallback(
    ({user_id, auth_token}) => {
      socketQuery({
        command: RESTORE_LOGIN,
        rid: RESTORE_LOGIN,
        params: {
          user_id,
          auth_token,
        },
      });
    },
    [socketQuery],
  );

  const register = useCallback(
    user_info =>
      socketQuery({
        command: REGISTER_USER,
        rid: REGISTER_USER,
        params: {
          user_info,
        },
      }),
    [socketQuery],
  );

  const loginEncrypted = useCallback(
    ({auth_token, jwe_token}) =>
      socketQuery({
        command: LOGIN_ENCRYPTED,
        rid: LOGIN_ENCRYPTED,
        params: {
          auth_token,
          jwe_token,
        },
      }),
    [socketQuery],
  );

  const getUser = useCallback(
    (rid = GET_USER) =>
      socketQuery({
        rid,
        params: {
          source: USER,
          what: {
            profile: [],
          },
          subscribe: true,
        },
        command: GET,
      }),
    [socketQuery],
  );

  const validateRegisterRecaptcha = useCallback(
    () =>
      socketQuery({
        rid: VALIDATE_REGISTER_RECAPTCHA,
        command: VALIDATE_RECAPTCHA,
        params: {m_hash: md5(`SESSION${sid}KEY${MOBILE_KEY}`)},
      }),
    [sid, socketQuery],
  );

  const validateRecaptcha = useCallback(
    ({action, g_recaptcha_response, version = 'v2'}) =>
      socketQuery({
        rid: VALIDATE_RECAPTCHA,
        command: VALIDATE_RECAPTCHA,
        params: {
          action,
          version,
          g_recaptcha_response,
        },
      }),
    [socketQuery],
  );

  const resetPasswordViaSms = useCallback(
    key =>
      socketQuery({
        rid: RESET_PASSWORD_VIA_SMS,
        command: RESET_PASSWORD_VIA_SMS,
        params: {
          key,
        },
      }),
    [socketQuery],
  );

  // const resetPassword = useCallback(
  //   ({new_password, reset_code}) =>
  //     socketQuery({
  //       rid: RESET_PASSWORD,
  //       command: RESET_PASSWORD,
  //       params: {
  //         reset_code,
  //         new_password,
  //       },
  //     }),
  //   [socketQuery],
  // );
  //
  const userUpdate = useCallback(
    user_info =>
      socketQuery({
        rid: UPDATE_USER,
        command: UPDATE_USER,
        params: {
          user_info,
        },
      }),
    [socketQuery],
  );

  const updateUserPassword = useCallback(
    ({password, new_password, confirmation_code, encrypted_token = false}) =>
      socketQuery({
        rid: UPDATE_USER_PASSWORD,
        command: UPDATE_USER_PASSWORD,
        params: {
          password,
          new_password,
          encrypted_token,
          confirmation_code,
        },
      }),
    [socketQuery],
  );

  const updateSelfExclusion = useCallback(
    ({limits, exc_type}) =>
      socketQuery({
        rid: SET_CLIENT_SELF_EXCLUSION,
        command: SET_CLIENT_SELF_EXCLUSION,
        params: {
          ...limits,
          exc_type,
        },
      }),
    [socketQuery],
  );

  const depositLimit = useCallback(
    limits =>
      socketQuery({
        rid: DEPOSIT,
        command: SET_USER_LIMIT,
        params: {
          limits,
          type: DEPOSIT,
        },
      }),
    [socketQuery],
  );

  const getDepositLimit = useCallback(
    () =>
      socketQuery({
        rid: USER_LIMIT,
        command: USER_LIMIT,
        params: {
          type: DEPOSIT,
        },
      }),
    [socketQuery],
  );

  const doBet = useCallback(
    ({rid = DO_BET, betsArray, amount, mode, type, sys_bet, bonus_id = null}) =>
      socketQuery({
        rid,
        command: DO_BET,
        params: {
          type,
          mode,
          amount,
          bets: betsArray,
          ...(sys_bet && {sys_bet}),
          ...(!!bonus_id && {bonus_id}),
        },
      }),
    [socketQuery],
  );

  const getMaxBet = useCallback(
    ({eventsArray, type, sys_min_count, rid = GET_MAX_BET}) =>
      socketQuery({
        rid,
        command: GET_MAX_BET,
        params: {
          type,
          events: eventsArray,
          ...(sys_min_count && {sys_min_count}),
        },
      }),
    [socketQuery],
  );

  const getOpenedBetsCount = useCallback(
    () =>
      socketQuery({
        rid: GET_OPENED_BETS_COUNT,
        command: GET_BETS_HISTORY,
        params: {
          where: {
            outcome: 0,
            only_counts: true,
          },
        },
      }),
    [socketQuery],
  );

  const getBetsHistory = useCallback(
    ({rid = GET_OPEN_BETS_HISTORY, outcome, from_date, to_date}) =>
      socketQuery({
        rid,
        command: GET_BETS_HISTORY,
        params: {
          where: {
            ...(to_date && {to_date}),
            ...(from_date && {from_date}),
            ...((!!outcome || outcome === 0) && {outcome}),
          },
        },
      }),
    [socketQuery],
  );

  const subscribeToCashOutAmount = useCallback(
    () =>
      socketQuery({
        rid: SUBSCRIBE_TO_CASH_OUT_AMOUNT,
        command: GET,
        params: {
          source: NOTIFICATIONS,
          what: {
            user: [],
          },
          subscribe: true,
        },
      }),
    [socketQuery],
  );

  const cashOut = useCallback(
    ({bet_id, mode = 2, price}) =>
      socketQuery({
        rid: CASH_OUT,
        command: CASH_OUT,
        params: {
          mode,
          price,
          bet_id,
        },
      }),
    [socketQuery],
  );

  const getPartnerConfig = useCallback(
    () =>
      socketQuery({
        command: GET,
        rid: GET_PARTNER_CONFIG,
        params: {
          source: GET_PARTNER_CONFIG,
          what: {partner: []},
          subscribe: true,
        },
      }),
    [socketQuery],
  );

  const getPaymentServices = useCallback(
    () =>
      socketQuery({
        rid: GET_PAYMENT_SERVICES,
        command: GET_PAYMENT_SERVICES,
        params: {
          detailed: true,
        },
      }),
    [socketQuery],
  );

  const getSportBonusRules = useCallback(
    () =>
      socketQuery({
        rid: GET_SPORT_BONUS_RULES,
        command: GET_SPORT_BONUS_RULES,
        params: {},
      }),
    [socketQuery],
  );

  const getLastWinners = useCallback(
    () =>
      socketQuery({
        rid: GET_PARTNER_LAST_WINS,
        command: GET_PARTNER_LAST_WINS,
        params: {
          count: 20,
        },
      }),
    [socketQuery],
  );

  const getTopWinners = useCallback(
    () =>
      socketQuery({
        rid: GET_PARTNER_BIG_WINS,
        command: GET_PARTNER_BIG_WINS,
        params: {
          count: 20,
        },
      }),
    [socketQuery],
  );

  const deposit = useCallback(
    ({amount, service, forProduct = 'sport'}) =>
      socketQuery({
        rid: MAKE_DEPOSIT,
        command: DEPOSIT,
        params: {
          amount,
          service,
          payer: {
            forProduct,
          },
        },
      }),
    [socketQuery],
  );

  const withdraw = useCallback(
    ({amount, service, forProduct = 'sport', office_id = null}) =>
      socketQuery({
        rid: WITHDRAW,
        command: WITHDRAW,
        params: {
          amount,
          service,
          payee: {
            forProduct,
            ...(office_id && {office_id: office_id}),
          },
        },
      }),
    [socketQuery],
  );

  // const getWithdrawals = useCallback(
  //   () =>
  //     socketQuery({
  //       rid: GET_WITHDRAWALS,
  //       command: GET_WITHDRAWALS,
  //       params: {},
  //     }),
  //   [socketQuery],
  // );
  //
  // const cancelWithdraw = useCallback(
  //   ({id}) =>
  //     socketQuery({
  //       rid: CANCEL_WITHDRAW,
  //       command: CANCEL_WITHDRAW,
  //       params: {
  //         id,
  //       },
  //     }),
  //   [socketQuery],
  // );

  const getTransactionHistory = useCallback(
    ({from_date, to_date, casino = false, type}) =>
      socketQuery({
        rid: GET_TRANSACTION_HISTORY,
        command: GET_TRANSACTION_HISTORY,
        params: {
          ...(casino && {product: 'Casino'}),
          where: {
            ...(to_date && {to_date}),
            ...(from_date && {from_date}),
            ...(type !== DEFAULT_TRANSACTION_TYPE && {type}),
          },
        },
      }),
    [socketQuery],
  );

  const getPartnerDocumentTypes = useCallback(
    () =>
      socketQuery({
        rid: GET_PARTNER_DOCUMENT_TYPES,
        command: GET_PARTNER_DOCUMENT_TYPES,
        params: {
          filter_id: 1,
        },
      }),
    [socketQuery],
  );

  // const applyPromoCode = useCallback(
  //   ({code}) =>
  //     socketQuery({
  //       rid: APPLY_PROMO_CODE,
  //       command: APPLY_PROMO_CODE,
  //       params: {
  //         code,
  //       },
  //     }),
  //   [socketQuery],
  // );

  const getMatchScores = useCallback(
    ({rid, match_id_list = []}) =>
      socketQuery({
        rid,
        command: GET_MATCH_SCORES,
        params: {
          match_id_list,
        },
      }),
    [socketQuery],
  );

  const checkBetStatus = useCallback(
    ({bet_id}) =>
      socketQuery({
        rid: CHECK_BET_STATUS,
        command: CHECK_BET_STATUS,
        params: {
          bet_id,
        },
      }),
    [socketQuery],
  );

  const sendSmsWithUsername = useCallback(
    ({username}) =>
      socketQuery({
        rid: SEND_SMS_WITH_USERNAME,
        command: SEND_SMS_WITH_USERNAME,
        params: {
          login: username,
        },
      }),
    [socketQuery],
  );

  const bookBet = useCallback(
    ({type, amount, bets}) =>
      socketQuery({
        rid: BOOK_BET,
        command: BOOK_BET,
        params: {
          type,
          bets,
          amount,
        },
      }),
    [socketQuery],
  );

  const getBookedBets = useCallback(
    ({booking_id}) =>
      socketQuery({
        rid: GET_BOOKED_BETS,
        command: GET_BOOKED_BETS,
        params: {
          booking_id,
        },
      }),
    [socketQuery],
  );

  const getFreeBetsForBetSlip = useCallback(
    ({betsArray, IsLive}) =>
      socketQuery({
        rid: GET_FREE_BETS_FOR_BETSLIP,
        command: GET_FREE_BETS_FOR_BETSLIP,
        params: {
          IsLive,
          Events: betsArray,
        },
      }),
    [socketQuery],
  );

  return {
    doBet,
    login,
    logout,
    getUser,
    bookBet,
    deposit,
    cashOut,
    getGames,
    checkBetStatus,
    register,
    loginEncrypted,
    withdraw,
    getSports,
    getMaxBet,
    readInbox,
    addUserMessage,
    getLeagues,
    getImages,
    userUpdate,
    getInboxes,
    deleteInbox,
    socketQuery,
    uploadImage,
    restoreLogin,
    depositLimit,
    // getMultiples,
    getTopWinners,
    removeSession,
    // resetPassword,
    // applyPromoCode,
    getMatchScores,
    getFavouriteCompetitionGames,
    // getWithdrawals,
    getBetsHistory,
    // cancelWithdraw,
    requestSession,
    getLastWinners,
    getInitialGames,
    unSubscribeBulk,
    getBoostedGames,
    getSpecificGame,
    // getDepositLimit,
    getBookedBets,
    getDepositLimit,
    getCompetitions,
    getPartnerConfig,
    subscribeToEvent,
    getBoostedSports,
    getFeaturedGames,
    // getMultipleGames,
    getSearchedGames,
    validateRecaptcha,
    getOpenedBetsCount,
    getSportBonusRules,
    updateUserPassword,
    getPaymentServices,
    sendSmsWithUsername,
    resetPasswordViaSms,
    updateSelfExclusion,
    unSubscribeAllEvents,
    getBoostedSelections,
    getSpecificGameStats,
    buddyToBuddyTransfer,
    getFreeBetsForBetSlip,
    getTransactionHistory,
    unSubscribeLiveEvents,
    getFixedCompetitions,
    changeSessionLanguage,
    getPopularCompetitions,
    getFavouriteCompetition,
    setFavouriteCompetition,
    getPartnerDocumentTypes,
    subscribeToCashOutAmount,
    deleteFavoriteCompetition,
    // subscribeToSpecificEvents,
    validateRegisterRecaptcha,
  };
};

export default useSocket;
