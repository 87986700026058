import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {AppButton, AppInput} from '../../UI';
import SearchGamesSkeletons from '../../UI/Skeletons/SearchGamesSkeletons';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import {checkIsToday} from '../../../Utils/TimeConvertors';

import {
  setShowMobileBetSlip,
  setShowSearchModal,
} from '../../../Redux/AppSlice';
import {
  getShowMobileBetSlip,
  getWssSocket,
} from '../../../Redux/AppSlice/AppSelectors';
import {
  MATCH_FILTERS,
  FILTERS_LIVE_NAME,
  FILTERS_TODAY_NAME,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_UPCOMING_MATCHES_NAME,
} from '../../../Constants/MatchFilters';
import {GET_SEARCHED_GAMES} from '../../../Constants/Socket';

import {ReactComponent as CloseIcon} from '../../../Assets/Icons/Globals/close.svg';

import {BREAKPOINT_MD, BREAKPOINT_XS} from '../../../Constants/Globals';

import './index.scss';

const SearchModal = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {width} = useScreenSize();
  const {getSearchedGames} = useSocket();
  const [getParam, setGetParam] = useSearchParams();
  const {setSearchedValuesToStorage, getSearchedValues} = useStorage();

  const dispatch = useDispatch();

  const wssSocket = useSelector(getWssSocket);
  const showMobileBetSip = useSelector(getShowMobileBetSlip);

  const inputRef = useRef(null);
  const timeOutRef = useRef(null);
  const searchValueRef = useRef(null);

  const [games, setGames] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [prevSearchedValues, setPrevSearchedValues] = useState([]);

  const gamesArray = useMemo(() => Object.values(games || {}), [games]);

  const searchValue = useMemo(() => getParam.get('search'), [getParam]);

  const openGame = useCallback(
    (sportItem, regionItem, competitionItem, gameItem) => {
      if (!!showMobileBetSip) {
        dispatch(setShowMobileBetSlip(false));
      }
      navigate({
        pathname: `/sports/${
          MATCH_FILTERS?.[
            gameItem?.type === 1
              ? FILTERS_LIVE_NAME
              : checkIsToday(gameItem?.start_ts)
              ? FILTERS_TODAY_NAME
              : competitionItem?.promoted === true && gameItem?.type !== 1
              ? FILTERS_POPULAR_MATCHES_NAME
              : FILTERS_UPCOMING_MATCHES_NAME
          ]?.name
        }/${sportItem?.alias}/${regionItem?.alias}/${competitionItem?.id}`,
        ...(gameItem?.id
          ? {
              search: createSearchParams({
                game: gameItem?.id,
              }).toString(),
            }
          : {}),
      });
      getParam.set('sportGameId', gameItem?.id);
      setGetParam(getParam);
      dispatch(setShowSearchModal(false));
    },
    [dispatch, getParam, navigate, setGetParam, showMobileBetSip],
  );

  const onCancel = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
    dispatch(setShowSearchModal(false));
  }, [dispatch, getParam, setGetParam]);

  const onClearSearch = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
  }, [getParam, setGetParam]);

  const handleInputChange = useCallback(
    text => {
      getParam.set('search', text);
      setGetParam(getParam);
      if (text?.trim()?.length === 0) {
        getParam.delete('search');
        setGetParam(getParam);
        setGames({});
      } else if (text?.trim()?.length > 2) {
        clearTimeout(timeOutRef.current);
        setIsLoading(true);
        timeOutRef.current = setTimeout(() => {
          getSearchedGames({search: text});
        }, 300);
      }
    },
    [getParam, getSearchedGames, setGetParam],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      if (data?.rid?.includes(GET_SEARCHED_GAMES)) {
        const searchParam = data?.rid?.split?.('_search=')?.[1]?.trim();

        if (
          (!searchParam?.trim?.() && !searchValue?.trim?.()) ||
          searchParam === searchValue?.trim?.()
        ) {
          setGames(data?.data?.data?.sport);
        }
        setTimeout(() => setIsLoading(false), 200);
      }
    },
    [searchValue],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    inputRef?.current?.focus?.();

    setPrevSearchedValues(getSearchedValues());

    return () => {
      if (searchValueRef?.current?.length > 2) {
        setSearchedValuesToStorage(searchValueRef?.current);
      }
    };
  }, []);

  useEffect(() => {
    document.querySelector('body').classList.add('overflow-hidden');
    return () =>
      document.querySelector('body').classList.remove('overflow-hidden');
  }, []);

  return (
    <div
      className={`fixed ${
        !(width < BREAKPOINT_XS) && 'items-center'
      } justify-center flex full-width full-height searchModalContainer z-max`}
      onClick={() => dispatch(setShowSearchModal(false))}>
      <div
        style={{
          ...(width < BREAKPOINT_XS
            ? {}
            : width < BREAKPOINT_MD
            ? {left: 20, top: 15, right: 16}
            : {left: 250, top: 16, right: 16}),
        }}
        className={`fixed wrapper ${width < BREAKPOINT_XS && 'mobileWrapper'}`}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}>
        <div className="flex row items-center gap-5">
          <AppInput
            search
            type={4}
            height={35}
            inputRef={inputRef}
            className="pa-sm mobileSize"
            onChange={handleInputChange}
            placeholder={t('searchTeam')}
            inputValue={searchValue || ''}
          />
          <AppButton
            type={5}
            onClick={onCancel}
            className={`resetButton`}
            title={
              <span className="text-caption bold-500">{t('cancelLower')}</span>
            }
          />
        </div>
        <div className="pa-lg mt-sm gamesContainer whiteBackground">
          {(searchValue || '')?.length < 3 ? (
            <div className="flex row items-center justify-between mb-xl">
              <span>Type more to get results ... </span>
              <AppButton
                disabled={!searchValue}
                onClick={onClearSearch}
                className="clearSearchButton"
                title={
                  <span className="text-caption-small">{t('clearSearch')}</span>
                }
              />
            </div>
          ) : (
            <div className="scroll-auto-y scroll-1 listContainer column gap-5 pr-lg whiteBackground">
              {isLoading ? (
                <SearchGamesSkeletons count={2} />
              ) : gamesArray?.length === 0 && searchValue?.length > 2 ? (
                <div className="flex justify-center">
                  <span className="textCenter font-24 py-xl full-width blueText">
                    {t('noGames')}
                  </span>
                </div>
              ) : (
                gamesArray?.map(sportItem =>
                  Object.values(sportItem?.region || {})?.map(regionItem =>
                    Object.values(regionItem?.competition || {})?.map(
                      competitionItem =>
                        Object.values(competitionItem?.game || {})?.map(
                          gameItem => (
                            <div
                              key={gameItem?.id}
                              onClick={() =>
                                openGame(
                                  sportItem,
                                  regionItem,
                                  competitionItem,
                                  gameItem,
                                )
                              }
                              className="cursor-pointer teamNameContainer rounded-borders px-md py-sm darkenGrayBackground row items-center gap-5">
                              <div className="row items-center gap-5">
                                {!!gameItem?.team1_name &&
                                  !!gameItem?.team2_name && (
                                    <img
                                      width={15}
                                      height={15}
                                      alt={gameItem?.team1_id}
                                      src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                                        gameItem?.team1_id / 2000,
                                      )}/${gameItem?.team1_id}.png`}
                                    />
                                  )}
                                <span className="text-body2 blueText bold-500">
                                  {gameItem?.team1_name}
                                </span>
                              </div>

                              {!!gameItem?.team2_name && (
                                <div className="row items-center gap-5">
                                  <span className="text-body2 blueText  bold-500">
                                    {' '}
                                    -{' '}
                                  </span>
                                  <img
                                    width={15}
                                    height={15}
                                    alt={gameItem?.team2_id}
                                    src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                                      gameItem?.team2_id / 2000,
                                    )}/${gameItem?.team2_id}.png`}
                                  />
                                </div>
                              )}
                              <span className="text-body2 blueText bold-500">
                                {gameItem?.team2_name}
                              </span>
                            </div>
                          ),
                        ),
                    ),
                  ),
                )
              )}
            </div>
          )}
          {!!prevSearchedValues?.length && (
            <div className="mt-lg whiteBackground">
              <span className="blueText">{t('resentSearches')}</span>
              <div className="flex gap-10 mt-md wrap">
                {prevSearchedValues?.map((item, index) => (
                  <AppButton
                    key={index}
                    onClick={() => handleInputChange(item?.val)}
                    title={
                      <div className="flex items-center">
                        <span className="blueText">{item?.val}</span>
                        <CloseIcon
                          width={10}
                          height={10}
                          fill="var(--appRed)"
                          className="ml-lg mt-xxs cursor-pointer"
                          onClick={e => {
                            e.stopPropagation();
                            setSearchedValuesToStorage(item?.val, true);
                            setTimeout(() => {
                              setPrevSearchedValues(getSearchedValues());
                            }, 100);
                          }}
                        />
                      </div>
                    }
                    className="resentSearchButton px-md py-sm darkenGrayBackground"
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SearchModal);
