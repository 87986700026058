import Modal from 'react-modal';
import {memo} from 'react';

import './index.scss';

import {
  APP_MODAL_CLOSE_TIMEOUT_MS,
  BREAKPOINT_XS,
} from '../../../Constants/Globals';

import {AppModalHeader} from '../index';

import close from '../../../Assets/Icons/Globals/close.svg';
import coloredClose from '../../../Assets/Icons/Globals/coloredClose.svg';
import {useScreenSize} from '../../../Hooks';

const AppModal = ({
  isOpen,
  children,
  header = null,
  headerType = 1,
  border = false,
  maxWidth = '1000px',
  maxHeight = '90dvh',
  closeIconType = 1,
  onAfterOpen = () => {},
  contentClassName = null,
  isCloseDisabled = false,
  onRequestClose = () => {},
  onModalWillShow = () => {},
  removeContentPaddings = false,
}) => {
  const {width} = useScreenSize();

  const style = {
    content: {
      maxHeight,
      border: 0,
      padding: 0,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      maxWidth: '95%',
      width: maxWidth,
      display: 'flex',
      borderRadius: 10,
      overflowY: 'auto',
      marginRight: '-50%',
      flexWrap: 'no-wrap',
      flexDirection: 'column',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {
      zIndex: 150,
      background: 'var(--lightBlack)',
    },
  };

  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onModalWillShow={onModalWillShow}
      bodyOpenClassName="overflow-hidden"
      htmlOpenClassName="overflow-hidden"
      closeTimeoutMS={APP_MODAL_CLOSE_TIMEOUT_MS}
      shouldCloseOnOverlayClick={!isCloseDisabled}
      onRequestClose={isCloseDisabled ? () => {} : onRequestClose}>
      {!isCloseDisabled &&
        (closeIconType === 1 ? (
          <img
            src={close}
            alt="close"
            onClick={onRequestClose}
            className="closeIcon cursor-pointer"
          />
        ) : closeIconType === 2 ? (
          <div
            style={{
              top: 5,
              right: 5,
              width: 40,
              height: 40,
            }}
            onClick={onRequestClose}
            className="whiteBackground items-center justify-center flex absolute z-index-1 rounded-borders cursor-pointer">
            <img alt="close" src={coloredClose} width={'auto'} />
          </div>
        ) : closeIconType === 3 ? (
          <div
            style={{
              top: 5,
              right: 5,
              width: 26,
              height: 26,
            }}
            onClick={onRequestClose}
            className="whiteBackground items-center justify-center flex absolute z-index-1 rounded-borders cursor-pointer">
            <img alt="close" src={coloredClose} width={9} />
          </div>
        ) : (
          <></>
        ))}
      {!!header && <AppModalHeader type={headerType}>{header}</AppModalHeader>}
      <div
        style={
          border
            ? {
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                border: '2px solid var(--appYellow)',
              }
            : {}
        }
        className={`${
          removeContentPaddings ? '' : 'pa-lg'
        } contentContainer scroll-auto-y scroll-1 items-start ${
          !!contentClassName ? contentClassName : ''
        }`}>
        {children}
      </div>
    </Modal>
  );
};

export default memo(AppModal);
