import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import GameItem from '../Games/GameItem';
import {AppButton, AppInput} from '../../UI';
import SearchGamesSkeletons from '../../UI/Skeletons/SearchGamesSkeletons';

import useCasino from '../../../Hooks/UseCasino';
import {useScreenSize, useStorage} from '../../../Hooks';

import {setShowCasinoSearchModal} from '../../../Redux/CasinoSlice';

import {ReactComponent as CloseIcon} from '../../../Assets/Icons/Globals/close.svg';

import {
  SKILL_NAME,
  SLOTS_NAME,
  LIVE_CASINO_NAME,
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SKILL_GAMES_ID,
  CATEGORY_SLOTS_GAMES_ID,
  PROVIDERS_ALL_GAMES_NAME,
  CATEGORY_BOOSTED_RTP_GAMES_ID,
} from '../../../Constants/CasinoMenuFilter';
import {
  BREAKPOINT_MD,
  BREAKPOINT_XS,
  BREAKPOINT_XXS,
} from '../../../Constants/Globals';

import './index.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

const CasinoSearchModal = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const params = useParams();
  const [getParam, setGetParam] = useSearchParams();

  const {
    getSearchedValues,
    setSearchedValuesToStorage,
    getLastPlayedGamesFromStorage,
  } = useStorage();
  const {getCasinoGames, goToCasinoGameScreen} = useCasino();

  const inputRef = useRef(null);
  const timeOutRef = useRef(null);
  const searchValueRef = useRef(null);

  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPlayedGames, setLastPlayedGames] = useState([]);
  const [lastPlayedGamesLoading, setLastPlayedGamesLoading] = useState(false);

  // const [prevSearchedValues, setPrevSearchedValues] = useState([]);

  const searchValue = useMemo(() => getParam.get('search'), [getParam]);
  const providerParam = useMemo(() => getParam.get('provider'), [getParam]);

  const onCancel = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
    dispatch(setShowCasinoSearchModal(false));
  }, [dispatch, getParam, setGetParam]);

  const onClearSearch = useCallback(() => {
    getParam.delete('search');
    setGetParam(getParam);
  }, [getParam, setGetParam]);

  const onItemClick = useCallback(
    (gameItem, {mode}) => {
      dispatch(setShowCasinoSearchModal(false));
      goToCasinoGameScreen({game: gameItem, mode: mode});
    },
    [dispatch, goToCasinoGameScreen],
  );

  // const closeIconHandler = useCallback(
  //   (e, item) => {
  //     e.stopPropagation();
  //     setSearchedValuesToStorage(item?.val, true, true);
  //     setTimeout(() => {
  //       setPrevSearchedValues(getSearchedValues(true));
  //     }, 100);
  //   },
  //   [getSearchedValues, setSearchedValuesToStorage],
  // );

  const handleInputChange = useCallback(
    text => {
      if (text?.trim()?.length === 0) {
        getParam.delete('search');
        setGetParam(getParam);
        setGames([]);
      } else {
        setIsLoading(true);
        getParam.set('search', text);
        setGetParam(getParam);

        clearTimeout(timeOutRef.current);

        timeOutRef.current = setTimeout(() => {
          getCasinoGames({
            searchQuery: text,
            provider:
              providerParam === PROVIDERS_ALL_GAMES_NAME ? '' : providerParam,
            category:
              params?.category === LIVE_CASINO_NAME
                ? CATEGORY_LIVE_CASINO_ID
                : params?.category === SKILL_NAME
                ? CATEGORY_SKILL_GAMES_ID
                : params?.category === 'boostedRTP'
                ? CATEGORY_BOOSTED_RTP_GAMES_ID
                : CATEGORY_SLOTS_GAMES_ID,
          })
            ?.then(res => {
              if (searchValueRef.current === text) {
                setGames(res?.data?.games);
              }
            })
            ?.finally(() => setIsLoading(false));
        }, 300);
      }
    },
    [getCasinoGames, getParam, params?.category, providerParam, setGetParam],
  );

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    inputRef?.current?.focus?.();

    getParam.delete('search');
    setGetParam(getParam);

    setLastPlayedGamesLoading(true);
    const externalIdsString = getLastPlayedGamesFromStorage()
      ?.filter(item =>
        params?.category === SLOTS_NAME
          ? item?.categoryId === CATEGORY_SLOTS_GAMES_ID
          : params?.category === LIVE_CASINO_NAME
          ? item?.categoryId === CATEGORY_LIVE_CASINO_ID
          : params?.category === SKILL_NAME
          ? CATEGORY_SKILL_GAMES_ID
          : params?.category === 'boostedRTP'
          ? item?.categoryId === CATEGORY_BOOSTED_RTP_GAMES_ID
          : true,
      )
      ?.sort((a, b) => b?.playedCount - a?.playedCount)
      ?.map(game => game?.gameId);

    if (externalIdsString?.length > 0) {
      getCasinoGames({
        limit: 30,
        externalIds: externalIdsString?.join(','),
      })
        ?.then?.(response => {
          const specificGames = response?.data?.games;

          const externalIdsString_str = externalIdsString.map(String);
          specificGames.sort(
            (a, b) =>
              externalIdsString_str.indexOf(a.extearnal_game_id) -
              externalIdsString_str.indexOf(b.extearnal_game_id),
          );

          setLastPlayedGames(specificGames);
        })
        ?.finally(() => {
          setTimeout(() => {
            setLastPlayedGamesLoading(false);
          }, 300);
          // setIsGameDataLoading(false)
        });
    } else {
      // setIsGameDataLoading(false);
    }

    return () => {
      setLastPlayedGames([]);
      // setPrevSearchedValues(getSearchedValues(true));
      // setSearchedValuesToStorage(searchValueRef?.current, false, true);
    };

    // setIsGameDataLoading(true);
  }, []);

  useEffect(() => {
    document.querySelector('body').classList.add('overflow-hidden');
    return () =>
      document.querySelector('body').classList.remove('overflow-hidden');
  }, []);

  return (
    <div>
      <div
        onClick={() => dispatch(setShowCasinoSearchModal(false))}
        style={{position: 'absolute', top: 0, zIndex: 111}}
        className="absolute-full"></div>
      <div
        style={
          width < BREAKPOINT_XS
            ? {
                background: 'var(--appBlue)',
                maxHeight: 'calc(100dvh - 46px)',
              }
            : {background: 'var(--appBlue)'}
        }
        className={`fixed ${
          width < BREAKPOINT_XS
            ? 'mobile full-width items-center'
            : 'desktop items-center'
        } justify-center flex full-width full-height searchModalContainer z-max`}
        onClick={() => dispatch(setShowCasinoSearchModal(false))}>
        <div
          style={{
            top: 15,
            ...(width < BREAKPOINT_XS ? {} : {left: 15, right: 15}),
          }}
          className={`fixed wrapper ${
            width < BREAKPOINT_XS && 'mobileWrapper'
          }`}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}>
          <div className="flex row items-center gap-10">
            <AppInput
              search
              type={6}
              height={30}
              searchType={2}
              inputRef={inputRef}
              onChange={handleInputChange}
              className="pa-sm mobileSize"
              inputValue={searchValue || ''}
              placeholder={t('searchGame')}
            />
            <AppButton
              type={5}
              height={30}
              onClick={onCancel}
              className={`resetButton flex justify-center items-center`}
              title={
                <span className="font-12 bold-600 text-uppercase">
                  {t('cancelLower')}
                </span>
              }
            />
          </div>
          <div className="gamesContainer blueBackground">
            {games?.length === 0 && !isLoading ? (
              <div className="py-md mt-sm">
                <div className="flex row items-center justify-between mb-xl">
                  <span
                    className={`whiteText ${
                      width < BREAKPOINT_XS && 'font-12'
                    }`}>
                    Type more to get results ...{' '}
                  </span>
                  <AppButton
                    onClick={onClearSearch}
                    className="clearSearchButton"
                    title={
                      <span className="text-caption-small">
                        {t('clearSearch')}
                      </span>
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="py-md mt-sm scroll-auto-y scroll-1 ">
                <div className="listContainer">
                  {isLoading ? (
                    <SearchGamesSkeletons count={2} />
                  ) : games?.length === 0 ? (
                    <div className="flex justify-center">
                      <span className="textCenter font-24 py-xl full-width blueText">
                        {t('noGames')}
                      </span>
                    </div>
                  ) : (
                    <div className="row wrap col-gutter-sm blueBackground">
                      {(games || [])?.map((gameItem, index) => (
                        <GameItem
                          isInModal
                          index={index}
                          gameItem={gameItem}
                          clickHandler={mode => onItemClick(gameItem, mode)}
                          key={
                            gameItem?.extearnal_game_id ?? gameItem?.id ?? index
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="py-md blueBackground">
              {!!lastPlayedGames?.length && (
                <div className="mt-lg gap-10 column">
                  <span className="whiteText bold-600 font-12">
                    {t('lastPlayed')}
                  </span>
                  <Swiper
                    freeMode
                    keyboard
                    mousewheel
                    spaceBetween={5}
                    slidesPerView={
                      width > BREAKPOINT_XS
                        ? 12
                        : width > BREAKPOINT_MD
                        ? 9
                        : width < BREAKPOINT_XXS
                        ? 4
                        : 7
                    }
                    modules={[Keyboard, FreeMode]}
                    className="casinoSearchModalLastPlayedSwiper full-width">
                    {(lastPlayedGames || [])?.map((item, index) => (
                      <SwiperSlide
                        key={item?.extearnal_game_id ?? item?.id ?? index}>
                        <GameItem
                          fullwidth
                          index={index}
                          gameItem={item}
                          clickHandler={mode => onItemClick(item, mode)}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/*{(lastPlayedGames || [])?.map((item, index) => (*/}
                  {/*  <GameItem*/}
                  {/*    isInModal*/}
                  {/*    gameItem={item}*/}
                  {/*    clickHandler={mode => onItemClick(item, mode)}*/}
                  {/*    key={item?.extearnal_game_id ?? item?.id ?? index}*/}
                  {/*  />*/}
                  {/*  // <AppButton*/}
                  {/*  //   key={index}*/}
                  {/*  //   onClick={() => handleInputChange(item?.val)}*/}
                  {/*  //   title={*/}
                  {/*  //     <div className="flex items-center">*/}
                  {/*  //       <span>{item?.val}</span>*/}
                  {/*  //       <CloseIcon*/}
                  {/*  //         width={10}*/}
                  {/*  //         height={10}*/}
                  {/*  //         fill="var(--appRed)"*/}
                  {/*  //         className="ml-lg mt-xxs cursor-pointer"*/}
                  {/*  //         onClick={e => closeIconHandler(e, item)}*/}
                  {/*  //       />*/}
                  {/*  //     </div>*/}
                  {/*  //   }*/}
                  {/*  //   className="resentSearchButton px-md py-sm"*/}
                  {/*  // />*/}
                  {/*))}*/}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CasinoSearchModal);
