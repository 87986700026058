import moment from 'moment/moment';

import {
  DATE_DEFAULT_FORMAT,
  DATE_FORMAT_WITH_HOURS_MINUTES,
} from '../Constants/Globals';

import i18next from 'i18next';

export const formatDate = (value, format = DATE_DEFAULT_FORMAT) =>
  moment(value)?.format(format);

export const formatUnixDate = (
  value,
  format = DATE_FORMAT_WITH_HOURS_MINUTES,
) => moment?.unix?.(value)?.format?.(format);

export const getDateColor = date => ({
  color: '#000000',
  // date?.indexOf(i18next.t('todayUpper')) > -1
  //   ? 'var(--lightGreen)'
  //   : date?.indexOf(i18next.t('tomorrowUpper')) > -1
  //   ? 'var(--darkAqua)'
  //   : 'gray',
});
