import React, {memo, useCallback, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getUser} from '../../../../Redux/UserSlice/UserSelectors';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {ReactComponent as TwitterIcon} from '../../../../Assets/Icons/Socials/x.svg';
import {ReactComponent as ShareIcon} from '../../../../Assets/Icons/Globals/share.svg';
import {ReactComponent as FacebookIcon} from '../../../../Assets/Icons/Socials/facebook.svg';
import {ReactComponent as TelegramIcon} from '../../../../Assets/Icons/Socials/telegram.svg';
import {ReactComponent as WhatsappIcon} from '../../../../Assets/Icons/Socials/whatsapp.svg';

const type3Styles = {
  border: '1px solid white',
};

const type3ContainerStyles = {
  borderLeft: '1px solid white',
  borderRight: '1px solid white',
  borderBottom: '1px solid white',
};

const BetSocialShare = ({betId, type = 1, finalPayout}) => {
  const {t} = useTranslation();

  const user = useSelector(getUser);

  const [isExpanded, setExpanded] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  const shareClickHandler = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();
      if (navigator.share) {
        try {
          await navigator.share({
            text: t('shareBetTitle', {
              amount: bigNumbersPrettier(fixedNumberWithoutRound(finalPayout)),
            }),
            title: t('shareBetTitle', {
              amount: bigNumbersPrettier(fixedNumberWithoutRound(finalPayout)),
            }),
            url: `https://pigabet.apsuite.ru/sports?sport=Soccer&gameFilter=today&user=${user?.first_name}&shareBetId=${betId}`,
          });
        } catch (error) {}
      } else {
        setExpanded(prev => !prev);
      }
    },
    [betId, finalPayout, t, user?.first_name],
  );

  return (
    <>
      <div
        className="full-width"
        {...getToggleProps({
          onClick: () => setExpanded(prevState => !prevState),
        })}>
        <div
          style={{height: 35, ...(type === 3 ? type3Styles : {})}}
          className={`whiteText ${
            type === 1
              ? 'newLightBlueBackground'
              : type === 2
              ? 'blueBackground'
              : type === 3
              ? ''
              : 'newLightBlueBackground'
          } px-md row items-center justify-between ${
            isExpanded ? 'rounded-top-borders' : 'rounded-borders'
          } `}>
          <span className="bold-700 font-15">{t('shareBet')}</span>
          <div
            onClick={shareClickHandler}
            style={{width: 25, height: 25, borderRadius: '50%'}}
            className="row items-center justify-center whiteBackground">
            <ShareIcon />
          </div>
        </div>
      </div>
      <div {...getCollapseProps()} className={`full-width`}>
        <div
          style={type === 3 ? type3ContainerStyles : {}}
          className={`flex justify-between items-center gap-5 pa-md ${
            type === 1
              ? 'newLightBlueBackground'
              : type === 2
              ? 'blueBackground'
              : 'newLightBlueBackground'
          } rounded-bottom-borders`}>
          <a
            target="_blank"
            rel="noreferrer"
            className="flex"
            href={`whatsapp://send?text=pigabet.apsuite.ru/sports?sport=Soccer&gameFilter=today&user=${user?.first_name}&shareBetId=${betId}`}>
            <FacebookIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="flex"
            href={`https://twitter.com/intent/tweet?url=pigabet.apsuite.ru/sports?sport=Soccer&gameFilter=today&user=${user?.first_name}&shareBetId=${betId}`}>
            <TwitterIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="flex"
            href={`https://t.me/share/url?url=${encodeURIComponent(
              'https://pigabet.apsuite.ru/sports?sport=Soccer&gameFilter=today&user=' +
                encodeURIComponent(user?.first_name) +
                '&shareBetId=' +
                encodeURIComponent(betId),
            )}`}>
            <TelegramIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            className="flex"
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              'https://pigabet.apsuite.ru/sports?sport=Soccer&gameFilter=today&user=' +
                encodeURIComponent(user?.first_name) +
                '&shareBetId=' +
                encodeURIComponent(betId),
            )}`}>
            <WhatsappIcon />
          </a>
        </div>
      </div>
    </>
  );
};

export default memo(BetSocialShare);
