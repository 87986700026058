import React, {memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  setMyBetsModal,
  setMessagesModal,
  setMainMenuModal,
  setShowMobileBetSlip,
  setShowBetSlip,
} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';

import {MAIN_MENU_DROPDOWN_ITEMS} from '../../../Constants/Menu';

import {useOutSideClickHandler, useSocket, useStorage} from '../../../Hooks';

import {ReactComponent as CopyIcon} from '../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as ArrowDownIcon} from '../../../Assets/Icons/Globals/arrowDown.svg';
import {
  BALANCE_MANAGEMENT,
  LEGAL_COMPLIANCE,
  MAIN_MENU_STACKS,
  MESSAGES,
  MY_BETS,
  PROMOTIONS,
  TRANSACTION_HISTORY,
} from '../../../Constants/MainMenu';
import {AppLanguageChooser} from '../../UI';
import {getLanguage} from '../../../Redux/AppSlice/AppSelectors';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

const MenuDropDown = ({setIsMainMenuDropDownOpen, contentRef}) => {
  const {t} = useTranslation();
  const {logout} = useSocket();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {getLoginDataFromStorage} = useStorage();
  const [getParam, setGetParam] = useSearchParams();

  useOutSideClickHandler(contentRef, () => setIsMainMenuDropDownOpen(false));

  const user = useSelector(getUser);
  const language = useSelector(getLanguage);

  const copyTimeoutRef = useRef(null);

  const [isCopied, setIsCopied] = useState(false);

  const copyIdHandler = useCallback(async () => {
    setIsCopied(true);
    await navigator.clipboard.writeText(user?.id);
    clearTimeout(copyTimeoutRef.current);
    copyTimeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  }, [user?.id]);

  const itemClick = useCallback(
    item => {
      switch (item?.tab) {
        case MY_BETS:
          dispatch(setMyBetsModal({isVisible: true}));
          break;
        case MESSAGES:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 3}));
          break;
        case BALANCE_MANAGEMENT:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 0}));
          break;
        case PROMOTIONS:
          getParam.set('showPromotions', 'all');
          setGetParam(getParam);
          break;
        case TRANSACTION_HISTORY:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 1}));
          break;
        case LEGAL_COMPLIANCE:
          navigate({
            pathname: '/privacy',
            search: `?${createSearchParams({
              tab: 'privacyPolicy',
            })}`,
          });
          break;
        default:
          dispatch(setMainMenuModal({isVisible: true, tabIndex: 2}));
          break;
      }
      setIsMainMenuDropDownOpen(false);
    },
    [dispatch, getParam, navigate, setGetParam, setIsMainMenuDropDownOpen],
  );

  const onLogoutClick = useCallback(async () => {
    const loginData = await getLoginDataFromStorage();
    logout({jwe_token: loginData?.jwe_token});
    dispatch(setMainMenuModal({isVisible: false}));
  }, [dispatch, getLoginDataFromStorage, logout]);

  // const menuItemClickHandler = useCallback(
  //   async action => {
  //     switch (action) {
  //       case 'myBets':
  //         dispatch(setMyBetsModal({isVisible: true}));
  //         break;
  //       case 'myProfile':
  //         dispatch(setMainMenuModal({isVisible: true, tabIndex: 2}));
  //         break;
  //       case 'messages':
  //         dispatch(setMessagesModal({isVisible: true}));
  //         break;
  //       case 'logout':
  //         const loginData = await getLoginDataFromStorage();
  //         logout({jwe_token: loginData?.jwe_token});
  //         break;
  //       default:
  //         break;
  //     }
  //     setIsMainMenuDropDownOpen(false);
  //   },
  //   [dispatch, getLoginDataFromStorage, logout, setIsMainMenuDropDownOpen],
  // );

  return (
    // <div className="mainMenuDropDownContainer absolute whiteText flex column justify-center gap-5">
    //   <div className="mainMenuDropDownContainerHeader flex column items-center gap-5">
    //     <span className=" font-14">{user?.phone}</span>
    //     <div className="flex row items-center gap-5">
    //       <span className=" font-14">ID:{user?.unique_id}</span>
    //       <CopyIcon
    //         width={11}
    //         height={11}
    //         fill="var(--appBlue)"
    //         onClick={copyIdHandler}
    //         className="cursor-pointer"
    //       />
    //     </div>
    //   </div>
    //   <div className="flex column gap-5 menuItemContainer">
    //     {MAIN_MENU_DROPDOWN_ITEMS.map((item, index) => (
    //       <div className="flex column gap-5 cursor-pointer" key={item?.name}>
    //         <div
    //           className="flex row  gap-10 items-center menuItem"
    //           onClick={() => menuItemClickHandler(item?.action)}>
    //           <img
    //             width={15}
    //             alt={item?.icon}
    //             src={require(
    //               `../../../Assets/Icons/MainMenu/${item?.icon}.svg`,
    //             )}
    //           />
    //           <span
    //             className={`${
    //               item?.name === 'logOut' ? 'redText' : ''
    //             } font-13`}>
    //             {t(item?.name)}
    //           </span>
    //         </div>
    //         {index !== MAIN_MENU_DROPDOWN_ITEMS?.length - 1 && (
    //           <div className="line" />
    //         )}
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="mainMenuDropDownContainer absolute whiteText flex column justify-center px-sm pt-lg pb-xs gap-5">
      <div
        style={{background: '#174277'}}
        className="flex justify-between paddingVertical-8 px-md rounded-borders">
        <div className="whiteText font-12 bold-600 gap-5">
          <span>{t(`playerId`)}</span>
          <span>{user?.id}</span>
        </div>
        <div
          onClick={copyIdHandler}
          className="flex rowCenter copyBetButton whiteBackground gap-5 cursor-pointer">
          <CopyIcon
            width={7}
            height={8}
            fill="#022751"
            className="cursor-pointer"
          />
          <span className="blueText font-10 text-center">
            {t(isCopied ? 'copied' : 'copy')}
          </span>
        </div>
      </div>
      <div className="flex column full-width gap-5">
        {MAIN_MENU_STACKS?.map(item => (
          <div
            key={item?.title}
            style={{background: '#174277'}}
            onClick={() => itemClick(item)}
            className="flex items-center justify-between paddingVertical-8 px-md cursor-pointer">
            <span className="whiteText bold-600 font-12">
              {t(`${item?.title}`)}
            </span>
            <img alt={item?.title} width={18} height={18} src={item?.icon} />
          </div>
        ))}
        <AppLanguageChooser
          ButtonComponent={
            <div
              style={{background: '#174277'}}
              className="flex items-center justify-between  mainMenuListItem paddingVertical-8 px-md cursor-pointer">
              <span className="whiteText font-12 bold-600">
                {t(`changeLanguage`)}
              </span>
              <div
                className="row items-center whiteBackground gap-3 pa-xxs pr-xs"
                style={{borderRadius: 2}}>
                <img
                  width={16}
                  height={10}
                  alt="choosedLanguage"
                  src={require(
                    `../../../Assets/Icons/Flags/${language || 'eng'}.svg`,
                  )}
                />
                <ArrowDownIcon width={7} height={7} fill="#003069" />
              </div>
            </div>
          }
        />
        <div
          onClick={onLogoutClick}
          className="flex items-center justify-center mainMenuListItem pa-md mt-xxxl cursor-pointer logOutButton">
          <span className="whiteText font-15 bold-600">{t(`logout`)}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(MenuDropDown);
