import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import './MarketsList.scss';

import {useScreenSize} from '../../../Hooks';

import MarketListItem from './MarketListItem';

import {BREAKPOINT_XS} from '../../../Constants/Globals';

const MarketsList = ({
  gameId,
  isBlocked,
  LIST_DATA,
  team1Name,
  team2Name,
  favouriteMarkets,
  setFavouriteMarkets,
}) => {
  const {t} = useTranslation();
  const {width} = useScreenSize();

  return (
    <div
      className={`flex column ${
        width < BREAKPOINT_XS ? 'mobile' : ''
      } marketsListWrapper gap-6`}>
      {LIST_DATA?.length > 0 || !!isBlocked ? (
        LIST_DATA?.map((listItem, index) => (
          <MarketListItem
            index={index}
            gameId={gameId}
            key={listItem?.key}
            marketItem={listItem}
            team2Name={team2Name}
            team1Name={team1Name}
            favouriteMarkets={favouriteMarkets}
            setFavouriteMarkets={setFavouriteMarkets}
          />
        ))
      ) : (
        <div className="row justify-center py-xl" style={{minHeight: 200}}>
          <span className="whiteText text-body1 bold-600">
            {t('emptyEvents')}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(MarketsList);
