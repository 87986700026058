import React, {memo, useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import './index.scss';

import {AppButton} from '../../UI';

import {useScreenSize} from '../../../Hooks';

import {getIsGameDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {
  MATCH_FILTERS,
  FILTERS_UPCOMING_MATCHES_NAME,
  FILTERS_POPULAR_COMPETITIONS_NAME,
  FILTERS_LIVE_NAME,
  FILTERS_TODAY_NAME,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_POPULAR_MATCHES_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../../../Constants/MatchFilters';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as LiveIcon} from '../../../Assets/Icons/SportFilters/live.svg';
import {ReactComponent as SearchIcon} from '../../../Assets/Icons/Globals/search.svg';
import {ReactComponent as TodayIcon} from '../../../Assets/Icons/SportFilters/today.svg';
import {ReactComponent as BoostedOddsIcon} from '../../../Assets/Icons/SportFilters/boostedOdds.svg';
import {ReactComponent as PopularMatchesIcon} from '../../../Assets/Icons/SportFilters/popularMatches.svg';
import {ReactComponent as UpcomingMatchesIcon} from '../../../Assets/Icons/SportFilters/upcomingMatches.svg';
import {ReactComponent as FavouriteCompetitionsIcon} from '../../../Assets/Icons/SportFilters/favouriteCompetitions.svg';
import {setShowCasinoSearchModal} from '../../../Redux/CasinoSlice';
import {setShowSearchModal} from '../../../Redux/AppSlice';

const SportFilters = ({isMobile = false}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam, setGetParam] = useSearchParams();

  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const clickHandler = useCallback(
    filterItem => {
      if (filterItem?.name === gameFilterParam) {
        return;
      }

      getParam.delete('region');
      if (filterItem?.name !== FILTERS_UPCOMING_MATCHES_NAME) {
        getParam.delete('day');
      }

      getParam.set('gameFilter', filterItem?.name);
      setGetParam(getParam);
    },
    [gameFilterParam, getParam, setGetParam],
  );

  return (
    <div
      className={`row items-center justify-end sportFiltersContainerWrapper gap-25 ${
        width > BREAKPOINT_XS ? 'desktop pl-xxxl py-md mb-md' : 'mobile'
      }`}>
      <Swiper
        keyboard
        freeMode
        mousewheel
        slidesPerView="auto"
        modules={[Keyboard, FreeMode]}
        spaceBetween={width < BREAKPOINT_XS ? 0 : 10}
        wrapperClass={width > BREAKPOINT_XS ? ' mx-auto 2' : ''}
        className={`sportFiltersSwiper ${
          width > BREAKPOINT_XS
            ? 'desktop full-width wrapperBorders'
            : 'mobile mt-sm '
        }`}>
        {Object.values(MATCH_FILTERS).map(matchItem => {
          const FilterIcon =
            matchItem?.icon === FILTERS_LIVE_NAME
              ? LiveIcon
              : matchItem?.icon === FILTERS_TODAY_NAME
              ? TodayIcon
              : matchItem?.icon === FILTERS_BOOSTED_ODDS_NAME
              ? BoostedOddsIcon
              : matchItem?.icon === FILTERS_POPULAR_MATCHES_NAME
              ? PopularMatchesIcon
              : matchItem?.icon === FILTERS_FAVOURITE_COMPETITIONS_NAME
              ? FavouriteCompetitionsIcon
              : UpcomingMatchesIcon;

          return (
            !(matchItem?.name === FILTERS_POPULAR_COMPETITIONS_NAME) && (
              <SwiperSlide
                key={matchItem?.name}
                className={`${width > BREAKPOINT_XS ? 'pa-xxs' : 'pa-xxs'}`}>
                <AppButton
                  type={isMobile ? 19 : 19}
                  onClick={() => clickHandler(matchItem)}
                  disabledWithoutLayer={isGameDataLoading}
                  className="paddingVertical-7 px-lg rounded-15"
                  active={gameFilterParam === matchItem?.name}
                  title={
                    <div className="flex row gap-7 items-center">
                      {matchItem?.icon && (
                        <FilterIcon
                          fill={
                            gameFilterParam === matchItem?.name
                              ? 'var(--appBlue)'
                              : 'white'
                          }
                        />
                      )}
                      <span className="bold-600 font-12">
                        {t(matchItem?.name)}
                      </span>
                    </div>
                  }
                />
              </SwiperSlide>
            )
          );
        })}
      </Swiper>
      {width > BREAKPOINT_XS && (
        <div
          className={`whiteBackground gameSearchContainer gap-6 row items-center px-md`}
          onClick={() => dispatch(setShowSearchModal(true))}>
          <SearchIcon width={10} height={10} fill="var(--appBlue)" />
          <span className="bold-700 font-12 blueText">
            {t('searchAnyGame')}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(SportFilters);
