import {createSelector} from '@reduxjs/toolkit';

const getSportState = state => state?.sport;

export const getSportsArray = createSelector(
  getSportState,
  state => state?.sports,
);

export const getActiveFilterParams = createSelector(
  getSportState,
  state => state?.activeFilterParams,
);

export const getIsGameDataLoading = createSelector(
  getSportState,
  state => state?.isGameDataLoading,
);

export const getIsPopularCompetitionsLoading = createSelector(
  getSportState,
  state => state?.isPopularCompetitionsLoading,
);

export const getIsSportDataLoading = createSelector(
  getSportState,
  state => state?.isSportDataLoading,
);

export const getSearchQuery = createSelector(
  getSportState,
  state => state?.searchQuery,
);

export const getBoostedGameIds = createSelector(
  getSportState,
  state => state?.boostedGameIds,
);

export const getShowSideBar = createSelector(
  getSportState,
  state => state?.showSideBar,
);

export const getFavouriteCompetitionsObject = createSelector(
  getSportState,
  state => state?.favouriteCompetitionsObject,
);

export const getSubIds = createSelector(getSportState, state => state?.subIds);
